import axiosServices from 'utils/axios';

export interface SavedSeach {
  name: string;
  query: string;
  orgName: string;
  repoName: string;
}

const savedSearchService = {
  getSaved: async (orgName: string, repoName: string) => {
    try {
      const response = await axiosServices.get<SavedSeach[]>(`/gh/orgs/${orgName}/repositories/${repoName}/search/saved`);
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  },
  addNew: async (orgName: string, repoName: string, savedSearch: SavedSeach) => {
    try {
      const response = await axiosServices.post(`/gh/orgs/${orgName}/repositories/${repoName}/search/saved`, { ...savedSearch });
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  },
  delete: async (orgName: string, repoName: string, name: string) => {
    try {
      const response = await axiosServices.delete(`/gh/orgs/${orgName}/repositories/${repoName}/search/saved/${name}`);
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  }
};

export default savedSearchService;
