import { SavedSeach } from 'api/services/savedSearchService';
import React, { ReactNode, createContext, useContext, useState } from 'react';

interface SavedSearchesType {
  savedSearches: SavedSeach[];
  addNewSavedSearches: (savedSearches: SavedSeach[]) => void;
  deleteSavedSearch: (name: string) => void;
}

const SavedSearches = createContext<SavedSearchesType | undefined>(undefined);

export const useSavedSearches = () => {
  const context = useContext(SavedSearches);
  if (!context) {
    throw new Error('useSavedSearches must be used within a SavedSearchProvider');
  }
  return context;
};

export const SavedSearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [savedSearches, setSavedSearches] = useState<SavedSeach[]>([]);

  // TODO: consider moving add/delete service calls here
  const addNewSavedSearches = (newSavedSearches: SavedSeach[]) => {
    setSavedSearches([...savedSearches, ...newSavedSearches]);
  };

  const deleteSavedSearch = (name: string) => {
    const newSavedSearches = savedSearches.filter((item) => item.name !== name);
    setSavedSearches(newSavedSearches);
  };

  return <SavedSearches.Provider value={{ savedSearches, addNewSavedSearches, deleteSavedSearch }}>{children}</SavedSearches.Provider>;
};
