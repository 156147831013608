import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Button, MenuItem, Box, Menu, styled } from '@mui/material';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import { useContext, useState } from 'react';

const ArrowDown = styled(FontAwesomeIcon)`
  width: 12px !important;
  height: 12px !important;
`;

const OrgPicker = () => {
  const { selectOrg, orgs, selectedOrg } = useContext(OrganizationContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOrgPickerOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography sx={{ fontSize: '0.8rem' }}>{'Organisation'}</Typography>
      <Button
        id="org-selector"
        variant="text"
        aria-controls={isOrgPickerOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOrgPickerOpen ? 'true' : undefined}
        onClick={handleClick}
        disableRipple={true}
        sx={{
          fontSize: '1rem',
          pr: '4px',
          color: '#000',
          padding: '0',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          '::after': {
            boxShadow: 'none'
          },
          ':hover': {
            background: 'none'
          }
        }}
        endIcon={<ArrowDown icon={faChevronDown} />}
      >
        <span style={{ marginRight: 0 }}>{selectedOrg ?? 'Select Organisation'}</span>
      </Button>
      <Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOrgPickerOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          {orgs.map((org, idx) => (
            <MenuItem
              key={idx}
              onClick={() => {
                selectOrg(org.organizationName);
                handleClose();
              }}
              sx={{ px: 4 }}
            >
              {org.organizationName}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default OrgPicker;
