import { Box, CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

const CenteredContainer = ({ children }: { children: ReactNode | string }) => (
  <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flex={1}>
    {children}
  </Box>
);

export default CenteredContainer;
