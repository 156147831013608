import axiosServices from '../../utils/axios';

export interface OrgToken {
  token: string;
  createAt: string;
  name: string;
}

export const tokenManagementService = {
  getTokens: async (org: string) => {
    try {
      const response = await axiosServices.get<OrgToken[]>(`/gh/orgs/${org}/tokens`);
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  },

  createToken: async (org: string, name: string) => {
    try {
      const response = await axiosServices.post<OrgToken>(`/gh/orgs/${org}/tokens?name=${name}`, {});
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  },

  deleteToken: async (org: string, name: string) => {
    try {
      const response = await axiosServices.delete<void>(`/gh/orgs/${org}/tokens/${name}`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  }
};
