import moment from 'moment';

const unitConverter = {
  convertDuration: (duration: number) => {
    const durationInSecs = (duration / 1000.0).toFixed(2);
    return `${durationInSecs} sec`;
  },

  convertCoverage: (n: number): string | null => {
    if (isNaN(n)) {
      return null;
    }

    const pct = (n * 100).toFixed(1);
    return `${pct}%`;
  },

  convertDate: (dateString: string) => {
    const date = moment.utc(dateString);
    const formattedDate = date.local().format('MMM Do HH:mm:ss');

    return formattedDate;
  }
};

export default unitConverter;
