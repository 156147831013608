// ==============================|| OVERRIDES - INPUT BASE ||============================== //

import { height } from '@mui/system';

export default function InputBase() {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            height: '40px'
          }
        },
        sizeSmall: {
          fontSize: '0.75rem'
        }
      }
    }
  };
}
