import { Outlet } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, Drawer, IconButton } from '@mui/material';

import Logo from 'components/logo';
import Navigation from './Navigation/Navigation';
import { UserCard } from './Navigation/user-card/UserCard';
import Header from './Navigation/Header';
import { useContext, useState } from 'react';
import { OrganizationContext } from 'contexts/OrganisationsContext';

const SIDEBAR_WIDTH = 300;
export const CONTAINER_MAX_WIDTH = '1800px';

const LogoWrapper = styled('div')({
  textAlign: 'left',
  display: 'inline-block',
  marginBottom: '16px'
});

const ComponentsLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isPublicOrg } = useContext(OrganizationContext);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        open={isSidebarOpen}
        onClose={toggleSidebar}
        variant="temporary"
        sx={{
          display: { xs: 'block', md: 'none' },
          zIndex: 100
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: SIDEBAR_WIDTH,
            zIndex: 100,
            borderRight: '1px dashed #E1E1E1',
            height: '100vh',
            px: '16px',
            pl: '24px',
            pt: 3
          }}
        >
          <LogoWrapper>
            <Logo to="/" />
          </LogoWrapper>
          {!isPublicOrg ? <UserCard /> : null}
          <Navigation handleNavigationClick={toggleSidebar} />
        </Box>
      </Drawer>

      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          width: { md: 275, lg: SIDEBAR_WIDTH },
          zIndex: 100,
          borderRight: '1px dashed #E1E1E1',
          height: '100vh',
          position: 'sticky',
          px: '16px',
          pl: '24px',
          left: 0,
          top: 0,
          pt: 3,
          flexShrink: 0
        }}
      >
        <LogoWrapper>
          <Logo to="/" />
        </LogoWrapper>
        {!isPublicOrg ? <UserCard /> : null}
        <Navigation />
      </Box>

      <Box sx={{ width: '100%', overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            padding: { xs: '0 24px 24px 24px', md: '0 48px 48px 48px' },
            maxWidth: '1800px',
            margin: '0 auto'
          }}
        >
          <Header handleDrawerButtonClick={toggleSidebar} hideUserNavigation={isPublicOrg} />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default ComponentsLayout;
