import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { LOCAL_STORAGE_REDIRECT_AFTER_LOGIN } from 'constants/constants';

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redriectTo = location.pathname;

    if (!isLoggedIn) {
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, redriectTo);

      navigate(`login`, {
        state: {
          from: location.pathname
        },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;
