import styled from '@mui/system/styled';
import { Chip } from '@mui/material';

export const OutcomeChip = styled(Chip)({
  textTransform: 'capitalize',
  borderRadius: '7px',
  minWidth: '80px',
  fontSize: '0.8rem',
  height: '28px',
  fontWeight: 500,
  padding: '0 8px',

  '&.broken': {
    backgroundColor: '#E04F4B',
    color: '#fff'
  },
  '&.fail': {
    backgroundColor: '#E04F4B',
    color: '#fff'
  },
  '&.flaky': {
    backgroundColor: '#E8A441',
    color: '#fff'
  },
  '&.good': {
    backgroundColor: '#2BA780',
    color: '#fff'
  },
  '&.success': {
    backgroundColor: '#2BA780',
    color: '#fff'
  },
  '&.unknown': {
    backgroundColor: '#cccccc',
    color: '#fff'
  },
  '&.skipping': {
    backgroundColor: '#FFC107',
    color: '#fff'
  },

  '& > span': {
    padding: 0
  }
});

export default OutcomeChip;
