import axiosServices from 'utils/axios';

type SetupCompletionResult = { complete: boolean };

const initializationService = {
  getSetupStatus: async (installationId: string) => {
    try {
      const response = await axiosServices.get<SetupCompletionResult>('/public/setup-complete', {
        params: {
          installationId
        }
      });
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  }
};

export default initializationService;
