import buildService, { BuildResponse } from 'api/services/buildService';
import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import { Typography } from '@mui/material';
import { useParams } from 'react-router';
import LinkStyled from 'components/LinkStyled';
import BuildsList from 'pages/builds/builds-list';
import { Helmet } from 'react-helmet';

const BuildsInPR = () => {
  const { pullRequestNumber, orgName, repoName } = useParams();
  const [builds, setBuilds] = useState<BuildResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [pageNumber, setPageNumber] = useState(1); // Track current page number
  const [hasMore, setHasMore] = useState(true); // Flag to determine if there are more pages

  const fetchBuilds = async (page: number) => {
    setIsLoading(true);
    setError(null);

    try {
      if (orgName && repoName && pullRequestNumber) {
        const data = await buildService.getBuildsInPR(orgName, repoName, pullRequestNumber, page);

        if (data) {
          setBuilds((prevBuilds) => [...prevBuilds, ...data.result]);
          setHasMore(data.pagination.currentPage !== data.pagination.totalPages);
        }
      }
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreItems = () => {
    if (!isLoading && hasMore) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const createPRLink = (pullRequestNumber: string) => {
    const githubLink = `https://github.com/${orgName}/${repoName}/pull/${pullRequestNumber}`;
    return (
      <LinkStyled to={githubLink} target="_blank">
        {'#' + pullRequestNumber}
      </LinkStyled>
    );
  };

  useEffect(() => {
    fetchBuilds(pageNumber);
  }, [pageNumber, orgName, repoName]);

  return (
    <>
      <Helmet>
        <title>PR Builds #{pullRequestNumber}</title>
      </Helmet>
      <Typography variant="h2" mb={2}>
        Builds in Pull Request {pullRequestNumber && createPRLink(pullRequestNumber)}
      </Typography>
      <BuildsList list={builds} loadMoreItems={loadMoreItems} hasMore={hasMore} isLoading={isLoading}></BuildsList>
    </>
  );
};

export default BuildsInPR;
