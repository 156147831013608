import { TableHead } from '@mui/material';
import styled from '@mui/system/styled';

const TableHeadStyled = styled(TableHead)`
    background: transparent;

    & .MuiTableCell-head {
        text-transform: none;
        font-size: 14px;
        font-weight: 600;

        &:after {
            display: none;
        }
    }
}`;

export default TableHeadStyled;
