import { ErrorResopnse } from 'api/types';
import axiosServices from 'utils/axios';

export interface FlakyTestBuild {
  testRunId: number;
  code: string;
  ciRunId: string;
  outcome: string;
  time: string;
  commitHash: string;
}

export interface FlakyTest {
  testContainer: string;
  testName: string;
  testMethodId: number;
  failedBuilds: FlakyTestBuild[];
  goodBuilds: FlakyTestBuild[];
}

export interface CurrentFlakyTests {
  quantity: number;
  flakyTests: FlakyTest[];
}

const flakyTestService = {
  getCurrent: async (orgName: string, repoName: string) => {
    try {
      const response = await axiosServices.get<CurrentFlakyTests>(`/gh/orgs/${orgName}/repositories/${repoName}/tests/flaky`);
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  }
};

export default flakyTestService;
