import { Box, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { BuildResponse } from 'api/services/buildService';
import MainCard from 'components/MainCard';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import TableHeadStyled from 'components/TableHeadStyled';
import LinkStyled from 'components/LinkStyled';
import styled from '@mui/system/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import unitConverter from 'utils/unitConverter';
import { useParams } from 'react-router';
import { getOrgRepoPath } from 'utils/routing-utils';

interface Props {
  list: BuildResponse[];
  loadMoreItems: () => void;
  hasMore: boolean;
  isLoading: boolean;
}

const StatusCircle = styled(`div`)({
  width: 20,
  height: 20,
  borderRadius: 10,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const StyledHeaderTableCell = styled(TableCell)({
  whiteSpace: 'nowrap'
});

const BuildsList = ({ list, loadMoreItems, hasMore, isLoading }: Props) => {
  const { orgName, repoName } = useParams();
  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useRef<HTMLTableRowElement | null>(null);
  const { isPublicOrg } = useContext(OrganizationContext);
  const path = useMemo(() => getOrgRepoPath(isPublicOrg, orgName, repoName), [orgName, repoName, isPublicOrg]);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting && hasMore) {
      loadMoreItems();
    }
  };

  useEffect(() => {
    if (lastItemRef.current) {
      observer.current = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '200px',
        threshold: 0.5
      });

      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [lastItemRef, hasMore, loadMoreItems]);

  const createPRLink = (pullRequestNumber: string) => {
    const githubLink = `https://github.com/${orgName}/${repoName}/pull/${pullRequestNumber}`;
    return (
      <LinkStyled to={githubLink} target="_blank">
        {'PR #' + pullRequestNumber}
      </LinkStyled>
    );
  };

  const getBranch = (isPullRequest: boolean, branch?: string) => {
    if (branch) {
      if (isPullRequest) {
        return createPRLink(branch);
      }
      return branch;
    }
  };

  return (
    <MainCard sx={{ height: '100%', '.MuiCardContent-root': { padding: 0 } }}>
      <TableContainer>
        <Table sx={{ minWidth: 350 }}>
          <TableHeadStyled>
            <TableRow>
              <StyledHeaderTableCell>Status</StyledHeaderTableCell>
              <StyledHeaderTableCell>Build Number</StyledHeaderTableCell>
              <StyledHeaderTableCell>Workflow</StyledHeaderTableCell>
              <StyledHeaderTableCell>Date</StyledHeaderTableCell>
              <StyledHeaderTableCell>Branch</StyledHeaderTableCell>
              <StyledHeaderTableCell>Configuration</StyledHeaderTableCell>
              <StyledHeaderTableCell># Success</StyledHeaderTableCell>
              <StyledHeaderTableCell># Fail</StyledHeaderTableCell>
              <StyledHeaderTableCell># Skip</StyledHeaderTableCell>
              <StyledHeaderTableCell># Error</StyledHeaderTableCell>
              <StyledHeaderTableCell>Test Execution Time</StyledHeaderTableCell>
            </TableRow>
          </TableHeadStyled>
          <TableBody>
            {list.map((data, idx) => (
              <TableRow key={idx} ref={idx === list.length - 1 ? lastItemRef : null}>
                <TableCell>
                  {!data.hasBrokenTests ? (
                    <Grid item sx={{ display: 'flex' }}>
                      <StatusCircle sx={{ background: '#00bd08' }}>
                        <FontAwesomeIcon icon={faCheck} size="sm" />
                      </StatusCircle>
                      <Box ml={1}>SUCCESS</Box>
                    </Grid>
                  ) : (
                    <Grid item sx={{ display: 'flex' }}>
                      <StatusCircle sx={{ background: '#e82323' }}>
                        <FontAwesomeIcon icon={faXmark} />
                      </StatusCircle>
                      <Box ml={1}>FAIL</Box>
                    </Grid>
                  )}
                </TableCell>
                <TableCell>
                  <LinkStyled to={`${path}/builds/${data.buildCode}`}>{data.ciRunID.toString().slice(0, 10)}</LinkStyled>
                </TableCell>
                <TableCell>{data.ciWorkflow + ' - ' + data.ciJob}</TableCell>
                <TableCell>{unitConverter.convertDate(data.date)}</TableCell>
                <TableCell>{getBranch(data.isPullRequest, data.branch)}</TableCell>
                <TableCell>
                  {(data.testConfiguration.operatingSystem ? 'OS: ' + data.testConfiguration.operatingSystem + ' ' : '') +
                    (data.testConfiguration.languageVersion ? 'LV: ' + data.testConfiguration.languageVersion + ' ' : '') +
                    (data.testConfiguration.testFrameworkVersion ? 'TF: ' + data.testConfiguration.testFrameworkVersion + ' ' : '')}
                </TableCell>
                <TableCell>{data.outcomes.success}</TableCell>
                <TableCell>{data.outcomes.fail}</TableCell>
                <TableCell>{data.outcomes.skip}</TableCell>
                <TableCell>{data.outcomes.error}</TableCell>
                <TableCell>{unitConverter.convertDuration(data.totalExecutionTime)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={4}
          sx={{ height: list.length ? 'auto' : '100%', display: 'flex', alignItems: 'center' }}
        >
          <CircularProgress size={40}></CircularProgress>
        </Box>
      ) : null}
    </MainCard>
  );
};

export default BuildsList;
