import { Stack, Typography, Box, IconButton, Collapse, IconButtonProps } from '@mui/material';

import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import { useContext, useEffect, useState } from 'react';

import { styled } from '@mui/system';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import OrgPicker from './OrgPicker';
import RepoPicker from './RepoPicker';

const MainCardStyled = styled(MainCard)`
  & .MuiCardContent-root {
    padding-bottom: 16px !important;
  }
`;

export const UserCard = () => {
  const { repos } = useContext(OrganizationContext);

  return (
    <MainCardStyled sx={{ background: '#f3f5f7', mt: 2, width: { xs: '80%', md: '100%' } }} id="org-repo-select">
      <OrgPicker />
      {repos.length ? <RepoPicker /> : null}
    </MainCardStyled>
  );
};
