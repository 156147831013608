import axios from 'axios';
import axiosRetry from 'axios-retry';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080/' });

axiosRetry(axiosServices, {
  retries: 2,
  retryDelay: () => 1000,
  retryCondition: (error: any) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.response?.status >= 500;
  }
});

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/login')) {
      // window.location.pathname = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;
