import { Alert } from '@mui/material';
import { tokenManagementService } from 'api/services/tokenManagementService';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import { Warning2 } from 'iconsax-react';
import { useContext, useEffect, useState } from 'react';

const MissingTokenBanner = () => {
  const { selectedOrg, isPublicOrg } = useContext(OrganizationContext);
  const [isTokenMissing, setIsTokenMissing] = useState<boolean>(false);

  useEffect(() => {
    const checkMissingToken = async () => {
      const tokens = await tokenManagementService.getTokens(selectedOrg!);
      setIsTokenMissing(tokens && tokens?.length > 0 ? false : true);
    };

    if (!isPublicOrg) {
      checkMissingToken();
    }
  }, [selectedOrg]);

  return (
    <>
      {isTokenMissing ? (
        <Alert color="warning" variant="border" icon={<Warning2 variant="Bold" />} sx={{ mb: 2, fontSize: 16 }}>
          Oops! It looks like the app token is missing. Please navigate to the Settings and generate a new app token.
        </Alert>
      ) : null}
    </>
  );
};

export default MissingTokenBanner;
