import { styled } from '@mui/material';
import MainCard from './MainCard';

export const StyledCeleberateImg = styled('img')({
  maxWidth: 450,
  height: 'auto',
  marginTop: '20px',
  marginBottom: '20px'
});

export const StyledContentWrapper = styled(MainCard)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '48px 84px 64px'
});
