import { ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router';
import { ThemeMode } from 'types/config';
import { Link } from 'react-router-dom';

type Props = {
  item: {
    id: number;
    title: string;
    icon: JSX.Element;
    path: string;
  };
  children?: string | JSX.Element | JSX.Element[] | null;
  handleNavigationClick?: () => void;
};
const NavigationItem = ({ item, children, handleNavigationClick }: Props) => {
  const location = useLocation();
  const theme = useTheme();
  const isSelected = location.pathname === item.path;

  const textActiveColor = theme.palette.mode === ThemeMode.DARK ? 'text.primary' : '#fff';
  const bgDefault = 'transparent';
  const bgActive = '#4680FF';

  return (
    <ListItemButton
      selected={isSelected}
      component={Link}
      onClick={handleNavigationClick}
      to={item.path}
      sx={{
        px: 2,
        py: 1,
        mb: 1.2,
        flexGrow: 0,
        bgcolor: isSelected ? bgActive : bgDefault,
        '&.Mui-selected': { bgcolor: bgActive, color: textActiveColor },
        '&.Mui-selected svg': { color: textActiveColor },
        '&.Mui-selected:hover': { bgcolor: '#3F78FF' }
      }}
    >
      <ListItemIcon sx={{ pr: 2, '& > svg': { width: '16px' } }}>{item.icon}</ListItemIcon>

      <ListItemText
        primary={
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {item.title}
          </Typography>
        }
      />
      {children}
    </ListItemButton>
  );
};

export default NavigationItem;
