import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';
import Logo from 'assets/images/logo/logo-text.svg';

import { APP_DEFAULT_PATH } from 'config';

interface Props {
  sx?: SxProps;
  to?: To;
  width?: number;
}

const LogoSection = ({ sx, to, width }: Props) => (
  <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx}>
    <img src={Logo} alt="icon logo" width={width ?? '180'} />
  </ButtonBase>
);

export default LogoSection;
