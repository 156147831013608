import { Org, Repository } from 'api/types/org';
import axiosServices from '../../utils/axios';

export interface OrgsReposData {
  isAdmin: boolean;
  organizationName: string;
  repositories: Array<Repository>;
}

const orgService = {
  getOrgs: async () => {
    try {
      const response = await axiosServices.get<Org[]>('/orgs');
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  },
  getOrgsRepos: async () => {
    try {
      const response = await axiosServices.get<OrgsReposData[]>('/orgs-repos-v2');
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  },
  getTeams: async (org: string) => {
    try {
      const response = await axiosServices.get<string[]>(`/gh/orgs/${org}/teams`);
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  },
  getRepositories: async (org: string) => {
    try {
      const response = await axiosServices.get<string[]>(`/gh/orgs/${org}/repositories`);
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  }
};

export default orgService;
