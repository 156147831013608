import { OrganizationContext } from 'contexts/OrganisationsContext';
import { useContext, useEffect, useState } from 'react';
import Joyride from 'react-joyride';

const storageKey = 'SESSION_STORAGE_NEW_USER_JOURNEY_COMPLETED';

const steps = [
  {
    target: '#org-repo-select',
    content: 'Select your organisation and repository',
    disableBeacon: true
  },
  {
    target: '#navigation-list',
    content: 'Navigate tabs to explore test health and coverage data',
    disableBeacon: true
  }
];

const NewUserJourney = () => {
  const [run, setRun] = useState<boolean>(false);
  const { orgs } = useContext(OrganizationContext);

  useEffect(() => {
    const shouldShowGuide = !localStorage.getItem(storageKey);

    if (shouldShowGuide && orgs.length) {
      localStorage.setItem(storageKey, 'true');

      setTimeout(() => {
        setRun(true);
      }, 2000);
    }
  }, [orgs]);

  return (
    <Joyride
      steps={steps}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      run={run}
      disableScrolling={true}
      styles={{
        options: {
          primaryColor: '#4680FF'
        }
      }}
    />
  );
};

export default NewUserJourney;
