import { OrganizationProvider } from 'contexts/OrganisationsContext';
import { SavedSearchProvider } from 'contexts/SavedSearchContext';
import { ReactNode } from 'react';

export const OSSWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <OrganizationProvider isOSS={true}>
        {/* We use SavedSearchProvider here to avoid rendering errors, but it should not be used for OSS */}
        <SavedSearchProvider>{children}</SavedSearchProvider>
      </OrganizationProvider>
    </>
  );
};
