import { ErrorResopnse } from 'api/types';
import axiosServices from 'utils/axios';

export interface CoveragePerType {
  [coverageType: string]: number;
}

export interface CoverageClassItem {
  filename: string;
  packageName: string;
  coveragePerType: CoveragePerType;
}

export interface CoverageClasses {
  classes: CoverageClassItem[];
}

export interface CoverageReportPackage {
  name: string;
  coveragePerType: CoveragePerType;
}

export interface CoverageReportClass {
  name: string;
  coveragePerType: CoveragePerType;
}

export interface CoverageReport {
  path: string;
  repositoryId: string;
  currentPackage: CoverageReportPackage;
  packages: Array<CoverageReportPackage>;
  classes: Array<CoverageReportClass>;
  folders: string[];
}

export interface LineCoverageResult {
  lineNumber: number;
  status: string;
}

export type CoverageLineStatus = 'COVERED' | 'NOT_COVERED';

export interface CoverageLine {
  lineNumber: number;
  status: CoverageLineStatus;
}

export type CoveredLines = Array<CoverageLine>;

export interface FileCoverageVisualizationResult {
  className: string;
  sourceCode: string;
  coveredLines: CoveredLines;
  sourceLanguage: string;
  commitLastAnalyzed: string;
}

const coverageService = {
  getCoverageClasses: async (org: string, repo: string, from?: number, to?: number, testType?: string) => {
    try {
      const response = await axiosServices.get<CoverageClasses>(`/gh/orgs/${org}/repositories/${repo}/coverage`, {
        params: {
          from,
          to,
          testType
        }
      });
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },

  getCoverageReport: async (org: string, repo: string, path?: string) => {
    try {
      const response = await axiosServices.get<CoverageReport>(`/gh/orgs/${org}/repositories/${repo}/coverage/browse`, {
        params: { path }
      });
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },

  getCoverageFile: async (org: string, repo: string, path: string, testType: string) => {
    try {
      const response = await axiosServices.get<FileCoverageVisualizationResult>(`/gh/orgs/${org}/repositories/${repo}/coverage/file`, {
        params: { testType, path }
      });
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },

  getTestTypes: async (org: string, repo: string) => {
    try {
      const response = await axiosServices.get<string[]>(`/gh/orgs/${org}/repositories/${repo}/coverage/test-types`);
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },

  getCurrentCoverage: async (org: string, repo: string) => {
    try {
      const response = await axiosServices.get<Record<string, number>>(`/gh/orgs/${org}/repositories/${repo}/coverage/current`);
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  }
};

export default coverageService;
