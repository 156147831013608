import pullRequestsService, { PullRequestsResponse } from 'api/services/pullRequestsService';
import { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import CenteredContainer from 'components/CenteredLoader';
import { useAlert } from 'contexts/AlretContext';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import PRsList from './prs-list';

const PullRequests = () => {
  const { orgName, repoName } = useParams();
  const [prs, setPRs] = useState<PullRequestsResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isInitLoading, setIsInitLoading] = useState<boolean | null>(null);
  const [pageNumber, setPageNumber] = useState(1); // Track current page number
  const [hasMore, setHasMore] = useState(true); // Flag to determine if there are more pages
  const [hasError, setHasError] = useState<boolean>(false);
  const alertProvider = useAlert();

  const fetchPRs = async (page: number) => {
    setHasError(false);

    if (orgName && repoName) {
      setIsLoading(true);

      if (isInitLoading === null) {
        setIsInitLoading(true);
      }

      const data = await pullRequestsService.getPullRequests(orgName, repoName, page).catch((e: Error) => {
        alertProvider.showAlert(`Problem retrieving builds data. \nError: ${e.message}`);
        setHasError(true);
      });

      setIsLoading(false);
      setIsInitLoading(false);

      if (data) {
        setPRs((prevPRs) => [...prevPRs, ...data.result]);
        setHasMore(data.pagination.currentPage !== data.pagination.totalPages);
      }
    }
  };

  const loadMoreItems = () => {
    if (!isLoading && hasMore) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    fetchPRs(pageNumber);
  }, [pageNumber, orgName, repoName]);

  return (
    <>
      <Helmet>
        <title>Pull Requests</title>
      </Helmet>
      <Typography variant="h2" mb={2}>
        Pull Requests
      </Typography>

      {!isInitLoading && !hasError ? (
        <PRsList list={prs} loadMoreItems={loadMoreItems} hasMore={hasMore} isLoading={isLoading}></PRsList>
      ) : null}

      {isInitLoading ? (
        <CenteredContainer>
          <CircularProgress></CircularProgress>
        </CenteredContainer>
      ) : null}
      {hasError ? (
        <CenteredContainer>
          <Typography ml={1} fontWeight={500} fontSize={'1.5rem'}>
            Data is not available
          </Typography>
        </CenteredContainer>
      ) : null}
    </>
  );
};

export default PullRequests;
