import React, { createContext, useState, useEffect, ReactNode, useMemo } from 'react';
import orgService, { OrgsReposData } from 'api/services/orgService';
import { Org, Repository } from 'api/types/org';
import useAuth from 'hooks/useAuth';
import coverageService from 'api/services/coverageService';
import { useNavigate } from 'react-router';

interface OrganizationContextType {
  orgs: OrgsReposData[];
  repos: Repository[];
  testTypes: string[];
  isPublicOrg: boolean;
  selectedOrg: string | null;
  selectedRepo: string | null;
  isSelectedOrgAdmin: boolean;
  isOrgSetupCompleted: boolean;
  selectOrg: (org: string | null, skipReload?: boolean) => void;
  selectRepo: (repo: string | null, skipReload?: boolean) => void;
}

const initialContext: OrganizationContextType = {
  orgs: [],
  repos: [],
  testTypes: [],
  selectedOrg: null,
  selectedRepo: null,
  isPublicOrg: false,
  isSelectedOrgAdmin: false,
  isOrgSetupCompleted: false,
  selectRepo: () => {},
  selectOrg: () => {}
};

const localStorageRepoKey = 'LOCAL_STORAGE_SELECTED_REPO';
const localStorageOrgKey = 'LOCAL_STORAGE_SELECTED_ORG';

const getStoredRepo = () => window.localStorage.getItem(localStorageRepoKey) ?? null;
const getStoredOrg = () => window.localStorage.getItem(localStorageOrgKey) ?? null;

const setStoredRepo = (val: string) => window.localStorage.setItem(localStorageRepoKey, val);
const setStoredOrg = (val: string) => window.localStorage.setItem(localStorageOrgKey, val);

const removeStoredRepo = () => window.localStorage.removeItem(localStorageRepoKey);
const removeStoredOrg = () => window.localStorage.removeItem(localStorageOrgKey);

export const OrganizationContext = createContext<OrganizationContextType>(initialContext);

export const OrganizationProvider: React.FC<{ children: ReactNode; isOSS?: boolean }> = ({ children, isOSS }) => {
  const navigate = useNavigate();

  const [isPublicOrg, setIsPublicOrg] = useState<boolean>(isOSS ?? false);
  const [orgs, setOrgs] = useState<OrgsReposData[]>([]);
  const [repos, setRepos] = useState<Repository[]>([]);

  const [selectedOrg, setSelectedOrg] = useState<string | null>(getStoredOrg);
  const [selectedRepo, setSelectedRepo] = useState<string | null>(getStoredRepo);
  const [testTypes, setTestTypes] = useState<string[]>([]);
  const [isSelectedOrgAdmin, setIsSelectedOrgAdmin] = useState<boolean>(false);
  const isOrgSetupCompleted: boolean = useMemo((): boolean => Boolean(selectedOrg && selectedRepo), [selectedOrg, selectedRepo]);

  useEffect(() => {
    const fetchOrgs = async () => {
      try {
        const data = await orgService.getOrgsRepos();
        if (data) {
          setOrgs(data);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchOrgs();
  }, []);

  useEffect(() => {
    if (orgs.length === 0) {
      return;
    }

    if (selectedOrg === null && orgs[0] != null) {
      selectOrg(orgs[0].organizationName);
    }
  }, [orgs, selectedOrg]);

  useEffect(() => {
    if (orgs.length === 0) {
      return;
    }

    if (selectOrg === null) {
      return;
    }

    const orgRepos = orgs.find((item) => item.organizationName === selectedOrg)?.repositories;
    setRepos(orgRepos ?? []);

    if (selectedRepo === null && orgRepos?.length) {
      selectRepo(orgRepos[0].name);
      setIsPublicOrg(orgRepos[0].isVisibleToEveryone);
    }
  }, [selectedOrg, orgs]);

  useEffect(() => {
    if (selectedOrg === null) {
      return;
    }

    const fetchTestTypes = async () => {
      try {
        if (selectedOrg && selectedRepo) {
          const data = await coverageService.getTestTypes(selectedOrg, selectedRepo);
          if (data) {
            setTestTypes(data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchTestTypes();
  }, [selectedRepo]);

  useEffect(() => {
    if (isPublicOrg) {
      return;
    }

    const org = orgs.find((item) => item.organizationName === selectedOrg);
    setIsSelectedOrgAdmin(!!org?.isAdmin);
  }, [selectedOrg, orgs]);

  const selectOrg = (org: string | null, skipReload?: boolean) => {
    const orgName = org ?? null;
    const isNewValue = getStoredOrg() !== orgName;

    if (orgName === null) {
      removeOrg();
      removeRepo();
      return;
    }

    if (isNewValue) {
      setSelectedOrg(orgName);
      setStoredOrg(orgName);
      removeRepo();

      if (!skipReload) {
        // reload page to refresh state
        navigate('/');
      }
    }
  };

  const selectRepo = (repoName: string | null, skipReload?: boolean) => {
    const isNewValue = getStoredRepo() !== repoName;

    const org = orgs.find((item) => item.organizationName === selectedOrg);
    const repo = org?.repositories.find((item) => item.name === repoName);

    if (repo == null) {
      removeRepo();
      return;
    }

    if (isNewValue) {
      setSelectedRepo(repo.name);
      setStoredRepo(repo.name);
      setIsPublicOrg(repo.isVisibleToEveryone);

      if (!skipReload) {
        // reload page to refresh state
        navigate('/');
      }
    }
  };

  const removeOrg = () => {
    removeStoredOrg();
    setSelectedOrg(null);
  };

  const removeRepo = () => {
    removeStoredRepo();
    setSelectedRepo(null);
    setIsPublicOrg(false);
  };

  return (
    <OrganizationContext.Provider
      value={{
        orgs,
        repos,
        testTypes,
        isPublicOrg,
        selectedOrg,
        selectedRepo,
        selectOrg,
        selectRepo,
        isSelectedOrgAdmin,
        isOrgSetupCompleted
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
