import axiosServices from '../../utils/axios';

export type FeedSntimentType = 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL';

export interface FeedResponse {
  createdAt: string;
  sentiment: FeedSntimentType;
  news: string;
  link: string;
}

const feedService = {
  getFeed: async (orgName: string, repoName: string) => {
    try {
      const response = await axiosServices.get<FeedResponse[]>(`/gh/orgs/${orgName}/repositories/${repoName}/feed`);
      return response.data;
    } catch (error) {
      console.error('Resonse error: ' + error);
      //   throw new Error(error);
    }
  }
};

export default feedService;
