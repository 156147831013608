import { Stack, TableCell, TableRow, IconButton, IconButtonProps, Collapse, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useContext, useMemo, useState } from 'react';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import LinkStyled from 'components/LinkStyled';
import { FlakyTest, FlakyTestBuild } from 'api/services/flakyTestService';
import unitConverter from 'utils/unitConverter';
import { useParams } from 'react-router';
import TruncateText from 'components/TruncateText';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import { getOrgRepoPath } from 'utils/routing-utils';

interface Props {
  flakyTest: FlakyTest;
}

const StatusCircle = styled(`div`)({
  width: 20,
  height: 20,
  borderRadius: 10,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const ExpandMore = styled((props: { expand: boolean } & IconButtonProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: '300ms'
}));

const StyledTableCell = styled(TableCell)({
  verticalAlign: 'top',
  paddingTop: 24
});

export const FlakyTestsListItem = ({ flakyTest }: Props) => {
  const { orgName, repoName } = useParams();
  const [latestBrokenBuild, ...brokenBuilds] = flakyTest.failedBuilds;
  const [latestGoodBuild, ...goodBuilds] = flakyTest.goodBuilds;
  const [buildsExpanded, setBuildsExpanded] = useState(false);
  const { isPublicOrg } = useContext(OrganizationContext);
  const path = useMemo(() => getOrgRepoPath(isPublicOrg, orgName, repoName), [orgName, repoName, isPublicOrg]);

  return (
    <TableRow>
      <StyledTableCell sx={{ mb: 'auto', maxWidth: '300px' }}>
        <TruncateText>
          <LinkStyled to={`${path}/tests/${flakyTest.testMethodId}`}>{flakyTest.testName}</LinkStyled>
        </TruncateText>
      </StyledTableCell>
      <StyledTableCell sx={{ maxWidth: '250px' }}>
        <TruncateText>{flakyTest.testContainer}</TruncateText>
      </StyledTableCell>
      <StyledTableCell>
        <Stack direction="row" alignItems={'center'} display={'flex'}>
          <Stack direction={'column'}>
            <BuildListItem testId={flakyTest.testMethodId} build={latestBrokenBuild}></BuildListItem>

            <Collapse in={buildsExpanded} timeout="auto" unmountOnExit>
              {brokenBuilds.map((build) => (
                <BuildListItem testId={flakyTest.testMethodId} build={build} key={build.testRunId}></BuildListItem>
              ))}
            </Collapse>
          </Stack>
        </Stack>
      </StyledTableCell>
      <StyledTableCell>
        <Stack direction="row" alignItems={'center'} display={'flex'}>
          <Stack direction={'column'}>
            <BuildListItem testId={flakyTest.testMethodId} build={latestGoodBuild}></BuildListItem>

            <Collapse in={buildsExpanded} timeout="auto" unmountOnExit>
              {goodBuilds.map((build) => (
                <BuildListItem testId={flakyTest.testMethodId} build={build} key={build.testRunId}></BuildListItem>
              ))}
            </Collapse>
          </Stack>

          {goodBuilds.length || brokenBuilds.length ? (
            <ExpandMore
              expand={buildsExpanded}
              color="secondary"
              id="wallet-button"
              aria-haspopup="true"
              sx={{ ml: 'auto', mb: 'auto', mt: -1 }}
              onClick={() => setBuildsExpanded(!buildsExpanded)}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </ExpandMore>
          ) : null}
        </Stack>
      </StyledTableCell>
    </TableRow>
  );
};

const BuildListItem = ({ build, testId }: { build: FlakyTestBuild; testId: number }) => {
  const { orgName, repoName } = useParams();
  const { isPublicOrg } = useContext(OrganizationContext);
  const path = useMemo(() => getOrgRepoPath(isPublicOrg, orgName, repoName), [orgName, repoName, isPublicOrg]);

  return (
    <Stack direction={'row'} mb={1}>
      {build?.outcome === 'SUCCESS' && (
        <StatusCircle sx={{ background: '#00bd08', mt: 0.25 }}>
          <FontAwesomeIcon icon={faCheck} size="sm" />
        </StatusCircle>
      )}
      {build?.outcome === 'FAIL' && (
        <StatusCircle sx={{ background: '#e82323' }}>
          <FontAwesomeIcon icon={faXmark} />
        </StatusCircle>
      )}
      <Tooltip title={'Build Date: ' + unitConverter.convertDate(build?.time)} placement="top">
        <LinkStyled sx={{ ml: 1 }} to={`${path}/tests/${testId}/run/${build?.testRunId}`}>
          {build?.ciRunId}
        </LinkStyled>
      </Tooltip>
    </Stack>
  );
};
