import { useEffect, useState } from 'react';

import Routes from 'routes';
import ThemeCustomization from 'themes';

import ScrollTop from 'components/ScrollTop';

import { JWTProvider as AuthProvider } from 'contexts/AuthContext';
import initializeGA from 'utils/ga';

const App = () => {
  const [loading, setLoading] = useState<boolean>(true);

  if (process.env.NODE_ENV === 'production') {
    initializeGA();
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <ThemeCustomization>
      <ScrollTop>
        <AuthProvider>
          <>
            <Routes />
          </>
        </AuthProvider>
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
