import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import MainCard from 'components/MainCard';
import { CurrentFlakyTests } from 'api/services/flakyTestService';
import { FlakyTestsListItem } from './flaky-tests-list-item';

interface Props {
  flakyTests: CurrentFlakyTests;
}

const TableHeadStyled = styled(TableHead)`
    background: transparent;

    & .MuiTableCell-head {
        text-transform: none;
        font-size: 14px;
        font-weight: 600;

        &:after {
            display: none;
        }
    }
}`;

const NoTestsMessage = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: 'auto',
  height: '200px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center'
});

const FlakyTestsList = ({ flakyTests }: Props) => {
  return (
    <MainCard content={false} sx={{ height: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ py: 2.5, pl: 2.5 }}>
        <Typography variant="subtitle1" sx={{ fontSize: '1rem' }}>
          Tests Count: {flakyTests?.quantity}
        </Typography>
      </Box>
      <TableContainer>
        {flakyTests.quantity === 0 ? (
          <NoTestsMessage>
            <Typography mt={12} fontSize={24} fontWeight={500}>
              No Flaky Tests Found
            </Typography>
          </NoTestsMessage>
        ) : null}
        <Table sx={{ minWidth: 350 }}>
          <TableHeadStyled>
            <TableRow>
              <TableCell>Test Name</TableCell>
              <TableCell>Container</TableCell>
              <TableCell>Failed Builds</TableCell>
              <TableCell>Successful Builds</TableCell>
            </TableRow>
          </TableHeadStyled>
          <TableBody>
            {flakyTests.flakyTests.map((data) => (
              <FlakyTestsListItem flakyTest={data} key={data.testMethodId} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default FlakyTestsList;
