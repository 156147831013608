import { ErrorResopnse } from 'api/types';
import axiosServices from 'utils/axios';

export interface SlowTest {
  id: number;
  testName: string;
  containerId: number;
  containerName: string;
  average: number;
  min: number;
  max: number;
  stdDeviation: number;
}

export interface SlowTestsResponse {
  slowTests: SlowTest[];
}

const slowTestService = {
  get: async (orgName: string, repoName: string) => {
    try {
      const response = await axiosServices.get<SlowTestsResponse>(`/gh/orgs/${orgName}/repositories/${repoName}/tests/slow`);
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  }
};

export default slowTestService;
