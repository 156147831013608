import buildService, { BuildResponse } from 'api/services/buildService';
import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import BuildsList from './builds-list';
import { CircularProgress, Typography } from '@mui/material';
import CenteredContainer from 'components/CenteredLoader';
import { useAlert } from 'contexts/AlretContext';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

const Builds = () => {
  const { orgName, repoName } = useParams();
  const [builds, setBuilds] = useState<BuildResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isInitLoading, setIsInitLoading] = useState<boolean | null>(null);
  const [pageNumber, setPageNumber] = useState(1); // Track current page number
  const [hasMore, setHasMore] = useState(true); // Flag to determine if there are more pages
  const [hasError, setHasError] = useState<boolean>(false);
  const alertProvider = useAlert();

  const fetchBuilds = async (page: number) => {
    setHasError(false);

    if (orgName && repoName) {
      setIsLoading(true);

      if (isInitLoading === null) {
        setIsInitLoading(true);
      }

      const data = await buildService.getBuilds(orgName, repoName, page).catch((e: Error) => {
        alertProvider.showAlert(`Problem retrieving builds data. \nError: ${e.message}`);
        setHasError(true);
      });

      setIsLoading(false);
      setIsInitLoading(false);

      if (data) {
        setBuilds((prevBuilds) => [...prevBuilds, ...data.result]);
        setHasMore(data.pagination.currentPage !== data.pagination.totalPages);
      }
    }
  };

  const loadMoreItems = () => {
    if (!isLoading && hasMore) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    fetchBuilds(pageNumber);
  }, [pageNumber, orgName, repoName]);

  return (
    <>
      <Helmet>
        <title>Builds</title>
      </Helmet>
      <Typography variant="h2" mb={2}>
        Builds
      </Typography>

      {!isInitLoading && !hasError ? (
        <BuildsList list={builds} loadMoreItems={loadMoreItems} hasMore={hasMore} isLoading={isLoading}></BuildsList>
      ) : null}

      {isInitLoading ? (
        <CenteredContainer>
          <CircularProgress></CircularProgress>
        </CenteredContainer>
      ) : null}
      {hasError ? (
        <CenteredContainer>
          <Typography ml={1} fontWeight={500} fontSize={'1.5rem'}>
            Data is not available
          </Typography>
        </CenteredContainer>
      ) : null}
    </>
  );
};

export default Builds;
