export const LOCAL_STORAGE_REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN';

export const TEST_RESULT_STATUSES: Record<string, { value: string; label: string }> = {
  flaky: {
    value: 'FLAKY',
    label: 'Flaky'
  },
  good: {
    value: 'GOOD',
    label: 'Good'
  },
  unknown: {
    value: 'UNKNOWN',
    label: 'Unknown'
  },
  broken: {
    value: 'BROKEN',
    label: 'Broken'
  },
  skipping: {
    value: 'SKIPPING',
    label: 'Skipping'
  }
};
