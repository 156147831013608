import styled from '@mui/system/styled';
import { Chip } from '@mui/material';

const ChipStyled = styled(Chip)({
  fontWeight: 600,
  color: '#fff',
  textTransform: 'uppercase',
  borderRadius: '7px'
});

export default ChipStyled;
