import { OrganizationContext } from 'contexts/OrganisationsContext';
import { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import slowTestService, { SlowTestsResponse } from 'api/services/slowTestService';
import SlowTestsList from './slow-tests-list';
import MainCard from 'components/MainCard';
import CenteredContainer from 'components/CenteredLoader';
import { useAlert } from 'contexts/AlretContext';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

const SlowTests = () => {
  const { orgName, repoName } = useParams();
  const [slowTests, setSlowTests] = useState<SlowTestsResponse>();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const alertProvider = useAlert();

  const fetchSlowTests = async () => {
    setHasError(false);

    if (orgName && repoName) {
      setIsLoading(true);

      const data = await slowTestService.get(orgName, repoName).catch((e: Error) => {
        alertProvider.showAlert(`Problem retrieving slow tests data. \nError: ${e.message}`);
        setHasError(true);
      });

      setIsLoading(false);

      if (data) {
        setSlowTests(data);
      }
    }
  };

  useEffect(() => {
    fetchSlowTests();
  }, [orgName, repoName]);

  return (
    <>
      <Helmet>
        <title>Slow Tests</title>
      </Helmet>
      <Typography variant="h2" mb={2}>
        Slow Tests
      </Typography>
      {slowTests && slowTests.slowTests.length > 0 ? <SlowTestsList slowTests={slowTests} /> : null}

      {slowTests?.slowTests.length === 0 ? (
        <Typography ml={1} fontWeight={500} fontSize={'1.5rem'}>
          We haven't collected any tests so far.
        </Typography>
      ) : null}

      {isLoading ? (
        <CenteredContainer>
          <CircularProgress></CircularProgress>
        </CenteredContainer>
      ) : null}

      {hasError ? (
        <CenteredContainer>
          <Typography ml={1} fontWeight={500} fontSize={'1.5rem'}>
            Data is not available
          </Typography>
        </CenteredContainer>
      ) : null}
    </>
  );
};

export default SlowTests;
