import { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import avatarImage from 'assets/images/users/avatar.png';
// material-ui
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import {
  Stack,
  Toolbar,
  Typography,
  Box,
  Popper,
  Paper,
  CardContent,
  ClickAwayListener,
  ButtonBase,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip
} from '@mui/material';
import Transitions from 'components/@extended/Transitions';
import IconButton from 'components/@extended/IconButton';
import Avatar from 'components/@extended/Avatar';
import useAuth from 'hooks/useAuth';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import MainCard from 'components/MainCard';
import { Logout, Setting } from 'iconsax-react';
import Notifications from './Notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export const HEADER_HEIGHT = '74px';

interface Props {
  handleDrawerButtonClick: () => void;
  hideUserNavigation: boolean;
}

const Header = ({ handleDrawerButtonClick, hideUserNavigation }: Props) => {
  const { selectedOrg, isSelectedOrgAdmin } = useContext(OrganizationContext);
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const theme = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const navigateSettings = () => navigate(`/${selectedOrg}/settings`);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        width: '100%',
        position: 'sticky',
        top: 0,
        background: 'transparent',
        zIndex: 100,
        height: '74px'
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: 'calc(100% + 30px)',
          position: 'absolute',
          top: 0,
          left: '-15px',
          margin: '0 auto',
          bgcolor: 'rgba(248, 249, 250, 0.3)',
          backdropFilter: 'blur(8px)'
        }}
      ></Box>
      <Box sx={{ m: 0, width: '100%' }}>
        <Toolbar sx={{ px: { xs: 0, sm: 0, md: 0, lg: 0 }, py: 1 }}>
          <Box>
            <IconButton
              color="secondary"
              sx={{
                display: { xs: 'block', md: 'none' },
                ml: -0.7,
                zIndex: 10
              }}
              onClick={handleDrawerButtonClick}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
          </Box>
          {!hideUserNavigation ? (
            <>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ marginLeft: 'auto' }}>
                <IconButton
                  color="secondary"
                  variant="light"
                  onClick={navigateSettings}
                  size="large"
                  sx={{ marginLeft: '8px !important', svg: { transform: 'scale(1.2)' } }}
                >
                  <Setting size={50} variant="Bulk" />
                </IconButton>
                <Notifications></Notifications>
                <ButtonBase ref={anchorRef} onClick={handleToggle} sx={{ marginLeft: '18px !important' }}>
                  <Avatar src={user && user.avatar ? decodeURIComponent(user?.avatar) : avatarImage} sx={{ cursor: 'pointer' }}></Avatar>
                </ButtonBase>
              </Stack>
            </>
          ) : null}

          <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            sx={{
              zIndex: 1000
            }}
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 9]
                  }
                }
              ]
            }}
          >
            {({ TransitionProps }) => (
              <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: 290,
                    minWidth: 240,
                    maxWidth: 290,
                    borderRadius: 1.5
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard border={false} content={false}>
                      <CardContent sx={{ p: 0 }}>
                        <Box sx={{ px: 2.5, pt: 3 }}>
                          <Stack direction={'row'}>
                            <Avatar src={avatarImage} sx={{ cursor: 'pointer' }}></Avatar>
                            <Box ml={2}>
                              {user ? <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{user.name}</Typography> : null}
                              {user ? (
                                <Typography sx={{ mt: -0.5, fontSize: 14, fontWeight: 400, color: '#5B6B79' }}>
                                  {user.githubLogin}
                                </Typography>
                              ) : null}
                            </Box>
                          </Stack>
                          {selectedOrg ? (
                            <Chip
                              sx={{ mt: 1.5 }}
                              label={isSelectedOrgAdmin ? 'Administrator' : 'Developer'}
                              size="small"
                              color="primary"
                            />
                          ) : null}
                        </Box>

                        <List component="nav" sx={{ padding: '0 8px', mt: 1.5, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
                          <ListItemButton
                            onClick={() => {
                              navigateSettings();
                              setOpen(false);
                            }}
                          >
                            <ListItemIcon>
                              <Setting variant="Bulk" size={18} />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                          </ListItemButton>
                          <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                              <Logout variant="Bulk" size={18} />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                          </ListItemButton>
                        </List>
                      </CardContent>
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </Popper>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Header;
