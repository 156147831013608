import { Box, CircularProgress, Grid, Typography, styled } from '@mui/material';
import AuthBackground from 'assets/images/auth/AuthBackground';
import { useState, useEffect } from 'react';
import welcome from 'assets/images/welcome.png';
import LogoSection from 'components/logo';
import initializationService from 'api/services/initializationService';
import { useSearchParams } from 'react-router-dom';
import GithubButton from './login/github-button';
import { Helmet } from 'react-helmet';

const StyledWelcomeImg = styled('img')({
  maxWidth: 450,
  width: '80%',
  height: 'auto'
});

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
  borderRadius: '10px',
  padding: '48px 84px 64px',
  boxShadow: '0px 4px 29px 0px rgba(0, 0, 0, 0.1)'
});

const Welcome = () => {
  const POLLING_INTERVAL = 3000;
  const MAX_ATTEMPTS = 60;

  const [searchParams] = useSearchParams();
  const installationId = searchParams.get('installationId');
  const [isSetupCompleted, setIsSetupCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Setup status polling
  useEffect(() => {
    let pollingInterval: any;
    let attempts = 0;

    if (!installationId) {
      setErrorMessage('Installation ID is missing.');
      return;
    }

    const fetchSetupStatus = async () => {
      try {
        attempts++;
        const data = await initializationService.getSetupStatus(installationId!);
        console.log(data);

        if (data?.complete === true) {
          setIsSetupCompleted(true);
        } else if (attempts < MAX_ATTEMPTS) {
          pollingInterval = setTimeout(fetchSetupStatus, POLLING_INTERVAL);
        } else {
          setErrorMessage('Setup took too long. Try reloading again later.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchSetupStatus();
    return () => clearTimeout(pollingInterval);
  }, [installationId, setIsSetupCompleted]);

  return (
    <>
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <Box sx={{ minHeight: '100vh' }}>
        <LogoSection sx={{ top: 24, left: 24, position: 'absolute' }}></LogoSection>
        <AuthBackground />
        <Grid
          container
          direction="column"
          justifyContent="center"
          sx={{
            minHeight: '100vh'
          }}
        >
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
            >
              <Grid item>
                <StyledContentWrapper>
                  <Typography fontSize={40} fontWeight={700}>
                    Great to have you here! 🎉
                  </Typography>
                  <StyledWelcomeImg src={welcome}></StyledWelcomeImg>

                  {isSetupCompleted ? (
                    <>
                      <Typography fontSize={20} textAlign={'center'}>
                        Setup is now completed!
                      </Typography>
                      <Box mt={1.5}>
                        <GithubButton></GithubButton>
                      </Box>
                    </>
                  ) : (
                    <>
                      {errorMessage ? (
                        <Typography color={'error'} fontSize={20} textAlign={'center'}>
                          Something went wrong during setup process. <br></br> Error: {errorMessage}
                        </Typography>
                      ) : (
                        <>
                          <Typography fontSize={20} textAlign={'center'}>
                            We are now configuring everything! <br></br>This might take a few seconds.
                          </Typography>
                          <CircularProgress sx={{ marginTop: 4 }} size={60} />
                        </>
                      )}
                    </>
                  )}
                </StyledContentWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Welcome;
