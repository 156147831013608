import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import MainCard from 'components/MainCard';
import { useEffect, useRef } from 'react';
import TableHeadStyled from 'components/TableHeadStyled';
import LinkStyled from 'components/LinkStyled';
import styled from '@mui/system/styled';
import { useParams } from 'react-router';
import { PullRequestsResponse } from 'api/services/pullRequestsService';

interface Props {
  list: PullRequestsResponse[];
  loadMoreItems: () => void;
  hasMore: boolean;
  isLoading: boolean;
}

const StyledHeaderTableCell = styled(TableCell)({
  whiteSpace: 'nowrap'
});

const PRsList = ({ list, loadMoreItems, hasMore, isLoading }: Props) => {
  const { orgName, repoName } = useParams();
  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useRef<HTMLTableRowElement | null>(null);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting && hasMore) {
      loadMoreItems();
    }
  };

  useEffect(() => {
    if (lastItemRef.current) {
      observer.current = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '200px',
        threshold: 0.5
      });

      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [lastItemRef, hasMore, loadMoreItems]);

  const formatCoveredBy = (coveredBy: Record<string, boolean>) => {
    let result = [];

    for (const el in coveredBy) {
      result.push(el + ': ' + coveredBy[el] + ' ');
    }

    return result;
  };

  const createPRLink = (pullRequestNumber: string) => {
    const githubLink = `https://github.com/${orgName}/${repoName}/pull/${pullRequestNumber}`;
    return (
      <LinkStyled to={githubLink} target="_blank">
        {'PR #' + pullRequestNumber}
      </LinkStyled>
    );
  };

  return (
    <MainCard sx={{ height: '100%', '.MuiCardContent-root': { padding: 0 } }}>
      <TableContainer>
        <Table sx={{ minWidth: 350 }}>
          <TableHeadStyled>
            <TableRow>
              <StyledHeaderTableCell>PR Number</StyledHeaderTableCell>
              <StyledHeaderTableCell>Covered By Tests</StyledHeaderTableCell>
              <StyledHeaderTableCell>Tests</StyledHeaderTableCell>
              <StyledHeaderTableCell>Number of builds</StyledHeaderTableCell>
            </TableRow>
          </TableHeadStyled>
          <TableBody>
            {list.map((data, idx) => (
              <TableRow key={idx} ref={idx === list.length - 1 ? lastItemRef : null}>
                <TableCell>{createPRLink(data.pullRequestNumber)}</TableCell>
                <TableCell>{data.coveredByTests.toString()}</TableCell>
                <TableCell>{formatCoveredBy(data.coveredBy)}</TableCell>
                <TableCell>{data.numberOfBuilds}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={4}
          sx={{ height: list.length ? 'auto' : '100%', display: 'flex', alignItems: 'center' }}
        >
          <CircularProgress size={40}></CircularProgress>
        </Box>
      ) : null}
    </MainCard>
  );
};

export default PRsList;
