import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { Alert, Snackbar, AlertColor } from '@mui/material';

interface AlertState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

interface AlertContextProps {
  showAlert: (message: string, severity?: AlertColor) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const useAlert = (): AlertContextProps => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: 'error'
  });

  const showAlert = useCallback((message: string, severity: AlertColor = 'error') => {
    setAlertState({
      open: true,
      message,
      severity
    });

    setTimeout(() => setAlertState({ open: false, message, severity }), 4000);
  }, []);

  const closeAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertState((prev) => ({ ...prev, open: false }));
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <Snackbar open={alertState.open} autoHideDuration={6000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity={alertState.severity} sx={{ width: '100%' }}>
          {alertState.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};
