import { createContext, useEffect, useReducer, ReactElement, useContext } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { KeyedObject } from 'types/root';
import { AuthProps, JWTContextType, UserProfile } from 'types/auth';

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);

  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null, user?: UserProfile | null) => {
  if (serviceToken && user) {
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('testpulseUser', JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('testpulseUser');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        const userStorage = localStorage.getItem('testpulseUser');

        if (serviceToken && userStorage && verifyToken(serviceToken)) {
          const user = JSON.parse(userStorage);

          setSession(serviceToken, user);

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (token: string, user: UserProfile) => {
    setSession(token, user);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: user
      }
    });
  };

  const incarnate = async () => {
    window.location.href = process.env.REACT_APP_API_URL + '/public/incarnate?user=mauricioaniche';
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, incarnate }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
