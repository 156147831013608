import styled from '@emotion/styled';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import MainCard from 'components/MainCard';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';

export interface BuildsSearchFilter {
  keywordValue: string;
  outcomeValue: string;
}

interface Props {
  onFilterChange: (filters: BuildsSearchFilter) => void;
  activeFilter: BuildsSearchFilter;
}

const BuildTestSearch = ({ onFilterChange, activeFilter }: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  const [keywordValue, setKeywordValue] = useState<string>(activeFilter.keywordValue);

  const handleOutcomeChange = (event: SelectChangeEvent<String>) => {
    const value = event.target.value as string;
    onFilterChange({ keywordValue: activeFilter.keywordValue, outcomeValue: value });
  };

  const handleFilterChange = () => {
    if (inputRef.current) {
      onFilterChange({ keywordValue: inputRef.current.value, outcomeValue: activeFilter.outcomeValue });
    }
  };

  const StackBlock = styled(Box)({
    margin: '10px'
  });

  return (
    <Stack flexDirection={'row'} padding={{ xs: '0', md: '8px 16px 8px 8px' }} alignItems={'center'}>
      <StackBlock width={{ xs: '55%', md: '80%' }}>
        <TextField
          autoFocus={inputRef.current === document.activeElement}
          fullWidth
          inputRef={inputRef}
          value={keywordValue}
          onChange={(e) => {
            setKeywordValue(e.target.value);
          }}
          id="outlined-basic"
          label="Search by name"
          onKeyUp={(e) => (e.key === 'Enter' ? handleFilterChange() : null)}
        />
      </StackBlock>
      <StackBlock width={{ xs: '45%', md: '20%' }}>
        <Select value={activeFilter.outcomeValue} fullWidth onChange={handleOutcomeChange}>
          <MenuItem value="ALL">All tests</MenuItem>
          <MenuItem value="SUCCESS">Success</MenuItem>
          <MenuItem value="FAIL">Fail</MenuItem>
          <MenuItem value="ERROR">Error</MenuItem>
          <MenuItem value="SKIP">Skipped</MenuItem>
          <MenuItem value="RETRIED">Retried</MenuItem>
        </Select>
      </StackBlock>
      <Button
        variant="contained"
        sx={{ px: 3, fontWeight: 500, height: '40px', width: '30px', ml: 1 }}
        onClick={() => handleFilterChange()}
      >
        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
      </Button>
    </Stack>
  );
};

export default BuildTestSearch;
