import { ErrorResopnse } from 'api/types';
import axiosServices from '../../utils/axios';

export interface Test {
  lastRun: {
    durationInMs: number;
    outcome: 'SUCCESS' | 'FAIL';
  };
  test: {
    id: string;
    testContainer: string;
    testContainerId: string;
    testName: string;
    teamNames: string[];
    averageExecTime: number;
    flakinessScore: number;
    testState: string;
  };
}

export interface TestConfiguration {
  operatingSystem?: string;
  languageVersion?: string;
  testFrameworkVersion?: string;
}

export interface TestRun {
  executionTimeInMs: number;
  ciRunID?: string;
  ciLink: string;
  testRunId?: string;
  isRetried: boolean;
  testRunIdRetriedOn?: string;
  outcome: 'SUCCESS' | 'FAIL' | 'SKIP' | 'RETRIED'; // TODO: confirm the list of possible outcomes
  date: string;
  testConfiguration: TestConfiguration;
  commitHash?: string;
  testpulseBuildCode?: string;
}

export interface TestDetailsInfo {
  averageExecutionTime: number;
  flakinessScore: number;
  testName: string;
  testState: string;
  testType: string;
  testStateExplanation: string;
  testRuns: TestRun[];
  teamNames: string[];
  codeRepository: string;
  organization: string;
  testContainerName: string;
  testContainerId: string;
  testConfigurations: TestConfiguration[];
}

export interface TestListTotals {
  broken: number;
  flaky: number;
  pass: number;
  unknown: number;
  skipping: number;
}

export interface PaginatedResult<T> {
  details: TestListTotals;
  pagination: {
    currentPage: number;
    totalItemsInPage: number;
    totalPages: number;
  };
  result: T;
}

export interface SearchCriteria {
  key: string;
  operator: string;
  value: string;
}

export interface SimilarlyFailedTestRun {
  buildCode: string;
  ciRunID: string;
  testMethodId: number;
  testRunId: number;
}

export interface ErrorCluster {
  occurrences: number;
  since: string;
  firstFailedTestRun: SimilarlyFailedTestRun;
  similarFailedRuns: SimilarlyFailedTestRun[];
}

export interface TestRunDetails {
  testContainer: string;
  testMethod: string;
  ciRunID: string;
  ciLink: string;
  date: string;
  testConfiguration: TestConfiguration;
  executionTimeInMs: number;
  outcome: string;
  additionalInformation?: string;
  systemOut?: string;
  systemError?: string;
  buildCode?: string;
  commitHash?: string;
  inMaster?: boolean;
  errorCluster?: ErrorCluster;
  isRetried?: boolean;
  testRunIdRetriedOn?: string;
}

export interface TestContainerDetails {
  testContainerName: string;
  codeRepository: string;
  teamNames: string[];
  testMethods: TestsInContainer[];
  good: number;
  flaky: number;
  broken: number;
  unknown: number;
  skipping: number;
}

export interface TestsInContainer {
  testId: string;
  testName: string;
  testState: string;
  flakinessScore: number;
  averageExecutionTime: number;
  testType: string;
}

export interface ErrorSummary {
  testRunIdFirstSeen: number;
  dateFirstSeen: string;
  testRunIdMostRecent: string;
  dateMostRecent: string;
  exampleStackTrace: string;
  occurrences: number;
}

export interface TestErrorsSummary {
  testId: number;
  errors: ErrorSummary[];
}

export const testsService = {
  getTests: async (org: string, repoName: string, activeFilters: SearchCriteria[], page: number) => {
    try {
      const criteria = [{ key: 'ORGANIZATION', operator: 'EQUAL', value: org }, ...activeFilters];

      const response = await axiosServices.get<PaginatedResult<Test[]>>(`/gh/orgs/${org}/repositories/${repoName}/tests`, {
        params: {
          criteria,
          page
        }
      });
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },
  getTestById: async (orgName: string, repoName: string, testId: string, days: number = 3) => {
    try {
      const response = await axiosServices.get<TestDetailsInfo>(`/gh/orgs/${orgName}/repositories/${repoName}/tests/${testId}`, {
        params: {
          days
        }
      });
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },
  getErrorsSummary: async (orgName: string, repoName: string, testId: string) => {
    try {
      const response = await axiosServices.get<TestErrorsSummary>(
        `/gh/orgs/${orgName}/repositories/${repoName}/tests/${testId}/errors/summary`
      );
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },
  getTestRun: async (orgName: string, repoName: string, testId: string, runId: string) => {
    try {
      const response = await axiosServices.get<TestRunDetails[]>(
        `/gh/orgs/${orgName}/repositories/${repoName}/tests/${testId}/run/${runId}`
      );
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  },
  getTestContainer: async (orgName: string, repoName: string, testContainerId: string) => {
    try {
      const response = await axiosServices.get<TestContainerDetails>(
        `/gh/orgs/${orgName}/repositories/${repoName}/test-containers/${testContainerId}`
      );
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  }
};
