import { ErrorResopnse } from 'api/types';
import axiosServices from 'utils/axios';

export interface PullRequestsResponse {
  pullRequestNumber: string;
  coveredByTests: boolean;
  coveredBy: Record<string, boolean>;
  numberOfBuilds: number;
}

export interface PullRequestsPaginatedResult {
  pagination: {
    currentPage: number;
    totalItemsInPage: number;
    totalPages: number;
  };
  result: PullRequestsResponse[];
}

const pullRequestsService = {
  getPullRequests: async (orgName: string, repoName: string, page: number = 1) => {
    try {
      const response = await axiosServices.get<PullRequestsPaginatedResult>(`/gh/orgs/${orgName}/repositories/${repoName}/prs`, {
        params: {
          page
        }
      });
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  }
};

export default pullRequestsService;
