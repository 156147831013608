import { OrganizationContext } from 'contexts/OrganisationsContext';
import React, { ReactNode, useContext, useEffect } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';

const NavigationInterceptor = ({ children }: { children: ReactNode }) => {
  const { selectedOrg, selectedRepo, selectOrg, selectRepo } = useContext(OrganizationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { orgName, repoName } = useParams();

  const navigateDefault = (orgName?: string) => {
    navigate(`/${orgName ?? selectedOrg}/${selectedRepo}/dashboard`);
  };

  useEffect(() => {
    const handleNavigation = (location: Location) => {
      if (location.pathname === '/') {
        navigateDefault();
        return;
      }

      if (orgName && orgName !== selectedOrg) {
        selectOrg(orgName, true);
      }

      if (repoName && repoName !== selectedRepo) {
        selectRepo(repoName);
      }
    };

    handleNavigation(location);
  }, [location, navigate]);

  return <>{children}</>;
};

export default NavigationInterceptor;
