import { useContext, useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  ClickAwayListener,
  Link,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

import { Check, Danger, Gift, InfoCircle, MessageText1, Notification, Setting2 } from 'iconsax-react';
import Avatar from 'components/@extended/Avatar';

import feedService, { FeedResponse } from 'api/services/feedService';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const sessionStorageKey = 'SESSION_STORAGE_LAST_NOTIFICATION_CHECK';

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

const NotificationPage = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedOrg, selectedRepo } = useContext(OrganizationContext);
  const [feed, setFeed] = useState<FeedResponse[]>([]);
  const [open, setOpen] = useState(false);
  const [lastNotificationCheck, setLastNotificationCheck] = useState<number | null>(null);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const navigate = useNavigate();
  const anchorRef = useRef<any>(null);

  const getFormattedTimestamp = (timestamp: string) =>
    moment(timestamp).calendar(null, {
      sameDay: 'hh:mm',
      lastDay: 'MMMM DD, hh:mm:ss',
      sameElse: 'MMMM DD, hh:mm:ss'
    });

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (open) {
      sessionStorage.setItem(sessionStorageKey, Date.now().toString());
      setLastNotificationCheck(Date.now());
    }
  }, [open]);

  useEffect(() => {
    if (lastNotificationCheck) {
      const unread = feed.filter((item) => moment(item.createdAt).valueOf() > lastNotificationCheck);
      setUnreadCount(unread.length);
    }
  }, [lastNotificationCheck, feed]);

  useEffect(() => {
    const lastChecked = sessionStorage.getItem(sessionStorageKey);

    if (lastChecked) {
      setLastNotificationCheck(Number(lastChecked));
    }
  }, []);

  const fetchFeed = async () => {
    if (selectedOrg && selectedRepo) {
      const data = await feedService.getFeed(selectedOrg, selectedRepo);

      if (data) {
        setFeed(data);
      }
    }
  };

  const feedLink = (event: FeedResponse) => {
    if (selectedOrg && selectedRepo) {
      return event.link.replace('{orgName}', selectedOrg).replace('{repoName}', selectedRepo);
    } else {
      return '#';
    }
  };

  useEffect(() => {
    fetchFeed();

    const intervalId = setInterval(() => {
      fetchFeed();
    }, 300000); // 5 minutes

    return () => clearInterval(intervalId);
  }, []);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ flexShrink: 0, marginLeft: '0 !important' }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="large"
        sx={{ marginLeft: '8px !important', svg: { transform: 'scale(1.1)' } }}
      >
        <Badge badgeContent={unreadCount} color="success" sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}>
          <Notification size={50} variant="Bulk" />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 1000
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{ overflow: 'hidden' }} in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                borderRadius: 1.5,
                width: '100%',
                minWidth: 350,
                maxWidth: 420
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" pb={2}>
                    <Typography variant="h5">Notifications</Typography>
                  </Stack>
                  <List
                    component="nav"
                    sx={{
                      pt: 0,
                      maxHeight: 350,
                      overflow: 'auto',
                      '& .MuiListItemButton-root': {
                        p: 1.5,
                        my: 1.5,
                        border: `1px solid ${theme.palette.divider}`,
                        '&:hover': {
                          bgcolor: 'primary.lighter',
                          borderColor: theme.palette.primary.light
                        },
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {feed.map((item, idx) => (
                      <ListItemButton key={idx} onClick={() => (window.location.href = feedLink(item))} sx={{ minWidth: 300 }}>
                        <ListItemAvatar>
                          {item.sentiment === 'NEGATIVE' ? (
                            <Avatar type="outlined" color="warning">
                              <Danger size={20} variant="Bold" />
                            </Avatar>
                          ) : null}
                          {item.sentiment === 'NEUTRAL' ? (
                            <Avatar type="outlined">
                              <InfoCircle size={20} variant="Bold" />
                            </Avatar>
                          ) : null}
                          {item.sentiment === 'POSITIVE' ? (
                            <Avatar type="outlined" color="success">
                              <FontAwesomeIcon icon={faCheck} />
                            </Avatar>
                          ) : null}
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="h6">{item.news}</Typography>}
                          secondary={moment(item.createdAt).fromNow()}
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="caption" noWrap>
                            {getFormattedTimestamp(item.createdAt)}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    ))}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default NotificationPage;
