import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography, Box, Link } from '@mui/material';
import { Stack, keyframes, styled } from '@mui/system';
import { BuildDetails, BuildTestRun } from 'api/services/buildService';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { useContext, useEffect, useRef } from 'react';
import { faCheck, faForward, faRecycle, faRotate, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router';
import LinkStyled from 'components/LinkStyled';
import unitConverter from 'utils/unitConverter';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import TruncateText from 'components/TruncateText';

interface Props {
  list: BuildTestRun[];
  loadMoreItems: () => void;
  buildDetails: BuildDetails;
  hasMore: boolean;
  isLoading: boolean;
}

const StatusCircle = styled(`div`)({
  width: 20,
  height: 20,
  borderRadius: 10,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const StyledTableRowHeader = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(225, 225, 225, 1)',
  height: 48,
  padding: '0 16px',
  background: '#fff',
  fontWeight: 500
});

const StyledTableRow = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(225, 225, 225, 1)',
  height: 48,
  padding: '0 16px',
  background: '#fff'
});

const highlightAnimation = keyframes`
  0% {
    background-color: transparent;
  }
  33% {
    background-color: transparent;
  }
  66% {
    background-color: #EDF3FF;
  }
  100% {
    background-color: transparent;
  }
`;

const BuildTestRunGridRow = styled(StyledTableRow)`
  &:hover {
    background-color: #f8f9fa;
  }
`;

const BuildTestList = ({ list, loadMoreItems, buildDetails, hasMore, isLoading }: Props) => {
  const { orgName, repoName } = useParams();
  const scrollRef = useRef<HTMLDivElement>(null);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useRef<HTMLTableRowElement | null>(null);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting && hasMore) {
      loadMoreItems();
    }
  };

  useEffect(() => {
    if (lastItemRef.current) {
      observer.current = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '200px',
        threshold: 0.5
      });

      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [lastItemRef, hasMore, loadMoreItems]);

  const truncate = (str: string) => {
    const segments: string[] = str.split('.');
    return segments[segments.length - 1];
  };

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}></Stack>
      {list.length ? (
        <Grid container ref={scrollRef} sx={{ height: '100%' }} minWidth={900}>
          <StyledTableRowHeader item xs={12} style={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <Grid container justifyContent="space-between">
              <Grid item sx={{ minWidth: '110px', width: '15%' }}>
                Outcome
              </Grid>
              <Grid item sx={{ width: '32.5%' }}>
                Test
              </Grid>
              <Grid item sx={{ width: '32.5%' }}>
                Container
              </Grid>
              <Grid item sx={{ minWidth: '120px', width: '20%' }}>
                Execution time
              </Grid>
            </Grid>
          </StyledTableRowHeader>
          {list.map((data, idx) => (
            <BuildTestRunGridRow item xs={12} key={idx} ref={idx === list.length - 1 ? lastItemRef : null}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item sx={{ minWidth: '110px', width: '15%', display: 'flex' }}>
                  <LinkStyled to={`/${orgName}/${repoName}/tests/${data.testId}/run/${data.testRunId}`}>
                    {data.outcome === 'SUCCESS' && (
                      <StatusCircle sx={{ background: '#00bd08' }}>
                        <FontAwesomeIcon icon={faCheck} size="sm" />
                      </StatusCircle>
                    )}
                    {data.outcome === 'FAIL' && (
                      <StatusCircle sx={{ background: '#e82323' }}>
                        <FontAwesomeIcon icon={faXmark} />
                      </StatusCircle>
                    )}
                    {data.outcome === 'SKIP' && (
                      <StatusCircle sx={{ background: '#ffc107' }}>
                        <FontAwesomeIcon size="xs" icon={faForward} />
                      </StatusCircle>
                    )}
                    {data.outcome === 'RETRIED' && (
                      <StatusCircle sx={{ background: '#3fc9d6' }}>
                        <FontAwesomeIcon size="xs" icon={faRotate} />
                      </StatusCircle>
                    )}
                  </LinkStyled>
                  <Box ml={1}>
                    <LinkStyled to={`/${orgName}/${repoName}/tests/${data.testId}/run/${data.testRunId}`}>{data.outcome}</LinkStyled>
                  </Box>
                </Grid>
                <Grid item sx={{ width: '32.5%', pr: '12px' }}>
                  <TruncateText>
                    <LinkStyled to={`/${orgName}/${repoName}/tests/${data.testId}`}>{data.testName}</LinkStyled>
                  </TruncateText>
                </Grid>
                <Grid item sx={{ width: '32.5%', pr: '12px' }}>
                  <TruncateText>
                    <LinkStyled to={`/${orgName}/${repoName}/testcontainer/${data.containerId}`}>{truncate(data.containerName)}</LinkStyled>
                  </TruncateText>
                </Grid>
                <Grid item sx={{ minWidth: '120px', width: '20%' }}>
                  {unitConverter.convertDuration(data.executionTimeInMs)}
                </Grid>
              </Grid>
            </BuildTestRunGridRow>
          ))}
        </Grid>
      ) : (
        <Typography textAlign={'center'} fontSize={18} margin="10px">
          No tests here!
        </Typography>
      )}
    </Box>
  );
};

export default BuildTestList;
