import { ErrorResopnse } from 'api/types';
import axiosServices from 'utils/axios';

export interface BrokenTestBuild {
  testRunId: number;
  code: string;
  ciRunId: string;
  outcome: string;
  time: string;
  commitHash: string;
}

export interface BrokenTest {
  testContainerId: number;
  testMethodId: number;
  testContainer: string;
  testContainerShortName: string;
  testName: string;
  since: string;
  lastBrokenBuilds: BrokenTestBuild[];
  explanation: string;
}

export interface CurrentBrokenTests {
  quantity: number;
  brokenTests: BrokenTest[];
}

const brokenTestService = {
  getCurrent: async (orgName: string, repoName: string) => {
    try {
      const response = await axiosServices.get<CurrentBrokenTests>(`/gh/orgs/${orgName}/repositories/${repoName}/tests/broken`);
      return response.data;
    } catch (e: any) {
      const message = (e as ErrorResopnse).error;
      throw new Error(message);
    }
  }
};

export default brokenTestService;
