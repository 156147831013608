import { useParams } from 'react-router';
import buildService, { BuildDetails, BuildPaginatedResult, BuildTestRun } from 'api/services/buildService';
import { useEffect, useState } from 'react';
import BuildDetailsHeader from './build-details-header';
import BuildTestList from './build-test-list';
import BuildTestSearch, { BuildsSearchFilter } from './build-test-search';
import MainCard from 'components/MainCard';
import { CircularProgress, Typography } from '@mui/material';
import { Clock } from 'iconsax-react';
import { useAlert } from 'contexts/AlretContext';
import CenteredContainer from 'components/CenteredLoader';
import { Helmet } from 'react-helmet';

const Build = () => {
  const { buildHash, orgName, repoName } = useParams();
  const [builds, setBuilds] = useState<BuildTestRun[]>([]);
  const [buildsDetails, setBuildsDetails] = useState<BuildDetails | null>(null);
  const [filter, setFilter] = useState<BuildsSearchFilter>({ outcomeValue: 'ALL', keywordValue: '' });

  const [isLoading, setIsLoading] = useState(false);
  const [isInitLoading, setIsInitLoading] = useState<boolean | null>(null);
  const [pageNumber, setPageNumber] = useState(1); // Track current page number
  const [hasMore, setHasMore] = useState(true); // Flag to determine if there are more pages
  const alertProvider = useAlert();

  const fetchBuilds = async (page: number) => {
    setIsLoading(true);

    if (buildHash && orgName && repoName) {
      if (isInitLoading === null) {
        setIsInitLoading(true);
      }

      const data = await buildService
        .getBuild(orgName, repoName, buildHash, filter.keywordValue, filter.outcomeValue, pageNumber)
        .catch((e: Error) => {
          alertProvider.showAlert(`Problem retrieving build data. \nError: ${e.message}`);
        });

      setIsLoading(false);
      setIsInitLoading(false);

      if (data) {
        setBuilds((prevBuilds) => [...prevBuilds, ...data.result]);
        setHasMore(data.pagination.currentPage !== data.pagination.totalPages);
        setBuildsDetails(data.details);
      }
    }
  };

  const loadMoreItems = () => {
    if (buildsDetails && hasMore) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (!isLoading && hasMore) {
      fetchBuilds(pageNumber);
    }
  }, [buildHash, filter, pageNumber]);

  const handleFilterChange = (filter: BuildsSearchFilter) => {
    setBuilds([]);
    setFilter(filter);
    setPageNumber(1);
    setHasMore(true);
  };

  if (buildsDetails && !buildsDetails.finishedProcessing) {
    return (
      <MainCard sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        <Clock size="150" color="#4680FF" />
        <Typography fontSize={30} fontWeight={600} mt={4}>
          We are still processing this build
        </Typography>
        <Typography fontSize={22} mt={2}>
          Try again shortly!
        </Typography>
      </MainCard>
    );
  }

  return (
    <>
      <Helmet>
        <title>Build {buildsDetails?.buildId ? `#${buildsDetails?.ciRunID}` : ''}</title>
      </Helmet>
      {!isInitLoading && buildsDetails ? (
        <>
          <BuildDetailsHeader details={buildsDetails} />

          <MainCard sx={{ marginTop: 2, width: '100%', overflow: 'hidden' }}>
            <BuildTestSearch onFilterChange={handleFilterChange} activeFilter={filter} />
            <BuildTestList
              list={builds}
              buildDetails={buildsDetails}
              loadMoreItems={loadMoreItems}
              hasMore={hasMore}
              isLoading={isLoading}
            />
          </MainCard>
        </>
      ) : null}
      {isInitLoading ? (
        <CenteredContainer>
          <CircularProgress></CircularProgress>
        </CenteredContainer>
      ) : null}
    </>
  );
};

export default Build;
