import { CurrentBrokenTests } from 'api/services/brokenTestService';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography, styled } from '@mui/material';
import MainCard from 'components/MainCard';
import { BrokenTestsListItem } from './broken-tests-list-item';
import TableHeadStyled from 'components/TableHeadStyled';

interface Props {
  brokenTests: CurrentBrokenTests;
}

const StyledHeaderTableCell = styled(TableCell)({
  whiteSpace: 'nowrap'
});

const BrokenTestsList = ({ brokenTests }: Props) => {
  return (
    <MainCard content={false} sx={{ height: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ py: 2.5, pl: 2.5 }}>
        <Typography variant="subtitle1" sx={{ fontSize: '1rem' }}>
          Tests Count: {brokenTests?.quantity}
        </Typography>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 350 }}>
          <TableHeadStyled>
            <TableRow>
              <StyledHeaderTableCell>Test Name</StyledHeaderTableCell>
              <StyledHeaderTableCell>Container</StyledHeaderTableCell>
              <StyledHeaderTableCell>Broken Since</StyledHeaderTableCell>
              <StyledHeaderTableCell>Explanation</StyledHeaderTableCell>
              <StyledHeaderTableCell>Broken builds</StyledHeaderTableCell>
            </TableRow>
          </TableHeadStyled>
          <TableBody>
            {brokenTests.brokenTests.map((data, idx) => (
              <BrokenTestsListItem brokenTest={data} key={idx}></BrokenTestsListItem>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default BrokenTestsList;
