import { Box } from '@mui/system';
import { ReactNode } from 'react';

const TruncateText = ({ children }: { children: ReactNode | string }) => {
  return (
    <Box
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {children}
    </Box>
  );
};

export default TruncateText;
