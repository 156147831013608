import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { LoggedInUserWrapper } from 'wrappers/LoggedInUserWrapper';
import Welcome from 'pages/welcome';
import Build from 'pages/build/build';
import Builds from 'pages/builds/builds';
import BrokenTests from 'pages/broken-tests/broken-tests';
import TestContainer from 'pages/test-container/test-container';
import FlakyTests from 'pages/flaky-tests/flaky-tests';
import SlowTests from 'pages/slow-tests/slow-tests';
import BuildsInPR from 'pages/builds-in-pr/builds-in-pr';
import { wrapUseRoutes } from '@sentry/react';
import { OSSWrapper } from 'wrappers/OSSWrapper';
import PullRequests from 'pages/pull-requests/pull-requests';

const AuthLogin = Loadable(lazy(() => import('pages/login/login')));
const HandleAuth = Loadable(lazy(() => import('pages/login/handle-auth')));

const TestsList = Loadable(lazy(() => import('pages/tests-list/tests-list')));
const TestDetails = Loadable(lazy(() => import('pages/test-details/test-details')));
const TestRunDiagnostics = Loadable(lazy(() => import('pages/test-run-diagnostics/test-run-diagnostics')));
const Dashboard = Loadable(lazy(() => import('pages/dashboard/dashboard')));
const Settings = Loadable(lazy(() => import('pages/settings/settings')));
const NotFound = Loadable(lazy(() => import('pages/404')));
const Coverage = Loadable(lazy(() => import('pages/coverage-summary/coverage-summary')));
const CoverageReport = Loadable(lazy(() => import('pages/coverage-report/coverage-report')));
const CoverageFile = Loadable(lazy(() => import('pages/coverage-file/coverage-file')));
const Install = Loadable(lazy(() => import('pages/install/install')));

const useSentryRoutes = wrapUseRoutes(useRoutes);

export default function ThemeRoutes() {
  return useSentryRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <LoggedInUserWrapper>
            <CommonLayout layout="dashboard" />
          </LoggedInUserWrapper>
        </AuthGuard>
      ),
      children: [
        {
          path: ':orgName/:repoName/tests',
          element: <TestsList />
        },
        {
          path: ':orgName/:repoName/tests/:testId',
          element: <TestDetails />
        },
        {
          path: ':orgName/:repoName/broken-tests',
          element: <BrokenTests />
        },
        {
          path: ':orgName/:repoName/flaky-tests',
          element: <FlakyTests />
        },
        {
          path: ':orgName/:repoName/slow-tests',
          element: <SlowTests />
        },
        {
          path: ':orgName/:repoName/builds/:buildHash',
          element: <Build />
        },
        {
          path: ':orgName/:repoName/tests/:testId/run/:runId',
          element: <TestRunDiagnostics />
        },
        {
          path: ':orgName/:repoName/dashboard',
          element: <Dashboard />
        },
        {
          path: ':orgName/settings',
          element: <Settings />
        },
        {
          path: ':orgName/:repoName/coverage',
          element: <Coverage />
        },
        {
          path: ':orgName/:repoName/coverage/file/:path',
          element: <CoverageFile />
        },
        {
          path: ':orgName/:repoName/coverage-report',
          element: <CoverageReport />
        },
        {
          path: ':orgName/:repoName/builds',
          element: <Builds />
        },
        {
          path: ':orgName/:repoName/testcontainer/:testContainerId',
          element: <TestContainer />
        },
        {
          path: ':orgName/:repoName/builds/pr/:pullRequestNumber',
          element: <BuildsInPR />
        },
        {
          path: ':orgName/:repoName/prs',
          element: <PullRequests />
        }
      ]
    },
    {
      path: '/oss',
      element: (
        <OSSWrapper>
          <CommonLayout layout="dashboard" />
        </OSSWrapper>
      ),
      children: [
        {
          path: ':orgName/:repoName/tests',
          element: <TestsList />
        },
        {
          path: ':orgName/:repoName/tests/:testId',
          element: <TestDetails />
        },
        {
          path: ':orgName/:repoName/broken-tests',
          element: <BrokenTests />
        },
        {
          path: ':orgName/:repoName/flaky-tests',
          element: <FlakyTests />
        },
        {
          path: ':orgName/:repoName/slow-tests',
          element: <SlowTests />
        },
        {
          path: ':orgName/:repoName/builds/:buildHash',
          element: <Build />
        },
        {
          path: ':orgName/:repoName/tests/:testId/run/:runId',
          element: <TestRunDiagnostics />
        },
        {
          path: ':orgName/:repoName/dashboard',
          element: <Dashboard />
        },
        {
          path: ':orgName/:repoName/coverage',
          element: <Coverage />
        },
        {
          path: ':orgName/:repoName/coverage/file/:path',
          element: <CoverageFile />
        },
        {
          path: ':orgName/:repoName/coverage-report',
          element: <CoverageReport />
        },
        {
          path: ':orgName/:repoName/builds',
          element: <Builds />
        },
        {
          path: ':orgName/:repoName/testcontainer/:testContainerId',
          element: <TestContainer />
        },
        {
          path: ':orgName/:repoName/builds/pr/:pullRequestNumber',
          element: <BuildsInPR />
        }
      ]
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to="login" replace />
        },
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'auth',
          element: <HandleAuth />
        },
        {
          path: 'welcome',
          element: <Welcome />
        }
      ]
    },
    {
      path: 'install',
      element: <Install />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]);
}
