import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import ComponentLayout from './ComponentLayout';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const FooterBlock = lazy(() => import('./FooterBlock'));

// ==============================|| LOADER - WRAPPER ||============================== //

const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = ({ layout = 'blank' }: { layout?: string }) => {
  return (
    <>
      {layout === 'landing' && (
        <Suspense fallback={<Loader />}>
          <Outlet />
          <FooterBlock isFull={layout === 'landing'} />
        </Suspense>
      )}
      {layout === 'dashboard' && (
        <Suspense fallback={<Loader />}>
          <ComponentLayout />
        </Suspense>
      )}
      {layout === 'blank' && <Outlet />}
    </>
  );
};

export default CommonLayout;
