import { Box, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableRow, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TestContainerDetails, testsService } from 'api/services/testsService';
import CenteredContainer from 'components/CenteredLoader';
import ChipStyled from 'components/ChipStyled';
import FlakinessScore from 'components/FlakinessScore';
import MainCard from 'components/MainCard';
import OutcomeChip from 'components/OutcomeChip';
import TableHeadStyled from 'components/TableHeadStyled';
import { useAlert } from 'contexts/AlretContext';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { getOrgRepoPath } from 'utils/routing-utils';
import unitConverter from 'utils/unitConverter';

const StyledTableHeaderCell = styled(TableCell)({
  whiteSpace: 'nowrap'
});

const TestContainer = () => {
  const { testContainerId, orgName, repoName } = useParams();

  const [testContainerDetails, setTestContainerDetails] = useState<TestContainerDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const alertProvider = useAlert();
  const navigate = useNavigate();
  const lastItemRef = useRef<HTMLTableRowElement | null>(null);
  const { isPublicOrg } = useContext(OrganizationContext);
  const path = useMemo(() => getOrgRepoPath(isPublicOrg, orgName, repoName), [orgName, repoName, isPublicOrg]);

  useEffect(() => {
    const fetchTestData = async () => {
      if (testContainerId && orgName && repoName) {
        setIsLoading(true);
        setHasError(false);

        const data = await testsService.getTestContainer(orgName, repoName, testContainerId).catch((e: Error) => {
          alertProvider.showAlert(`Problem retrieving container data. \nError: ${e.message}`);
          setHasError(true);
        });
        setIsLoading(false);

        if (data) {
          setTestContainerDetails(data);
        }
      }
    };

    fetchTestData();
  }, [testContainerId]);

  const goToTest = (testId: string) => {
    navigate(`${path}/tests/${testId}`);
  };

  return (
    <>
      <Helmet>
        <title>{testContainerDetails?.testContainerName ?? 'Test Container'}</title>
      </Helmet>
      {!isLoading && !hasError && testContainerDetails ? (
        <MainCard>
          <Typography variant="body1" sx={{ fontSize: 25, fontWeight: 600 }}>
            {testContainerDetails?.testContainerName}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: 20, fontWeight: 400 }}>
            {testContainerDetails?.codeRepository}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: 20, fontWeight: 400 }}>
            {testContainerDetails?.teamNames}
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ py: 2.5 }}>
            <Typography variant="subtitle1" sx={{ fontSize: '1rem', display: { xs: 'none', md: 'block' } }}>
              {testContainerDetails?.testMethods.length} Tests
            </Typography>
            <Stack direction="row" spacing={{ xs: 0.75, md: 2 }} sx={{ p: 0 }}>
              <>
                <ChipStyled
                  label={`${testContainerDetails?.broken ?? 0} Broken`}
                  sx={{ backgroundColor: '#E04F4B', '& span': { padding: { xs: '4px 7px', md: '4px 12px' } } }}
                />
                <ChipStyled
                  label={`${testContainerDetails?.flaky ?? 0} Flaky`}
                  sx={{ backgroundColor: '#E8A441', '& span': { padding: { xs: '4px 7px', md: '4px 12px' } } }}
                />
                <ChipStyled
                  label={`${testContainerDetails?.good ?? 0} Good`}
                  sx={{ backgroundColor: '#2BA780', '& span': { padding: { xs: '4px 7px', md: '4px 12px' } } }}
                />
                <ChipStyled
                  label={`${testContainerDetails?.skipping ?? 0} Skipping`}
                  sx={{ backgroundColor: '#FFC107', '& span': { padding: { xs: '4px 7px', md: '4px 12px' } } }}
                />
                <ChipStyled
                  label={`${testContainerDetails?.unknown ?? 0} Unknown`}
                  sx={{ backgroundColor: '#4680FF', '& span': { padding: { xs: '4px 7px', md: '4px 12px' } } }}
                />
              </>
            </Stack>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 350 }}>
              <TableHeadStyled>
                <TableRow>
                  <StyledTableHeaderCell>Test Name</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Test Type</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Test State</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Flakiness Score</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Average Exec Time</StyledTableHeaderCell>
                </TableRow>
              </TableHeadStyled>
              <TableBody>
                {testContainerDetails?.testMethods.map((data, idx) => (
                  <TableRow
                    sx={{ cursor: 'pointer' }}
                    key={idx}
                    ref={idx === testContainerDetails?.testMethods.length - 1 ? lastItemRef : null}
                    onClick={() => goToTest(data.testId)}
                  >
                    <TableCell>{data.testName}</TableCell>
                    <TableCell>{data.testType}</TableCell>
                    <TableCell>
                      <OutcomeChip sx={{ mt: 1, p: 1 }} label={data.testState} className={data.testState.toLocaleLowerCase()}></OutcomeChip>
                    </TableCell>
                    <TableCell>
                      <FlakinessScore className={getFlakinessScoreClassName(data.flakinessScore)}>
                        {data.flakinessScore.toFixed(2)}
                      </FlakinessScore>
                    </TableCell>
                    <TableCell>{unitConverter.convertDuration(data.averageExecutionTime)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      ) : null}
      {isLoading ? (
        <CenteredContainer>
          <CircularProgress></CircularProgress>
        </CenteredContainer>
      ) : null}
      {hasError ? (
        <CenteredContainer>
          <Typography ml={1} fontWeight={500} fontSize={'1.5rem'}>
            Data is not available
          </Typography>
        </CenteredContainer>
      ) : null}
    </>
  );
};

const getFlakinessScoreClassName = (score: number) => {
  if (score <= 10) {
    return 'good';
  } else if (score <= 30) {
    return 'moderate';
  }

  return 'bad';
};

export default TestContainer;
