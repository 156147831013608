import MissingTokenBanner from 'components/MissingTokenBanner';
import NewUserJourney from 'components/NewUserJourney';
import { OrganizationProvider } from 'contexts/OrganisationsContext';
import { SavedSearchProvider } from 'contexts/SavedSearchContext';
import NavigationInterceptor from 'interceptors/router-interseptor';
import { ReactNode } from 'react';

export const LoggedInUserWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <OrganizationProvider>
        <NewUserJourney></NewUserJourney>
        <MissingTokenBanner></MissingTokenBanner>
        <NavigationInterceptor>
          <SavedSearchProvider>{children}</SavedSearchProvider>
        </NavigationInterceptor>
      </OrganizationProvider>
    </>
  );
};
