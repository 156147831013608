import styled from '@mui/system/styled';
import { Box } from '@mui/material';

const FlakinessScore = styled(Box)({
  '&.bad': {
    color: '#E04F4B'
  },
  '&.moderate': {
    color: '#E8A441'
  }
});

export default FlakinessScore;
