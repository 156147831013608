import { Box, Link, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import TableHeadStyled from 'components/TableHeadStyled';
import { SlowTestsResponse } from 'api/services/slowTestService';
import unitConverter from 'utils/unitConverter';
import { styled } from '@mui/system';
import { useParams } from 'react-router';
import TruncateText from 'components/TruncateText';
import { OrganizationContext } from 'contexts/OrganisationsContext';
import { useContext, useMemo } from 'react';
import { getOrgRepoPath } from 'utils/routing-utils';

interface Props {
  slowTests: SlowTestsResponse;
}

const StyledHeaderTableCell = styled(TableCell)({
  whiteSpace: 'nowrap'
});

const SlowTestsList = ({ slowTests }: Props) => {
  const { orgName, repoName } = useParams();
  const { isPublicOrg } = useContext(OrganizationContext);
  const path = useMemo(() => getOrgRepoPath(isPublicOrg, orgName, repoName), [orgName, repoName, isPublicOrg]);

  return (
    <MainCard content={false} sx={{ height: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ py: 2.5, pl: 2.5 }}>
        <Typography variant="subtitle1" sx={{ fontSize: '1rem' }}>
          Top 50 slowest tests
        </Typography>
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: 350 }}>
          <TableHeadStyled>
            <TableRow>
              <StyledHeaderTableCell width="35%">Test Name</StyledHeaderTableCell>
              <StyledHeaderTableCell width="35%">Container</StyledHeaderTableCell>
              <StyledHeaderTableCell>Execution time (past month)</StyledHeaderTableCell>
            </TableRow>
          </TableHeadStyled>
          <TableBody>
            {slowTests.slowTests.map((data, idx) => (
              <TableRow key={idx}>
                <TableCell sx={{ maxWidth: '350px' }}>
                  <TruncateText>
                    <Link href={`${path}/tests/${data.id}`}>{data.testName}</Link>
                  </TruncateText>
                </TableCell>
                <TableCell sx={{ maxWidth: '350px' }}>
                  <TruncateText>
                    <Link href={`${path}/testcontainer/${data.containerId}`}>{data.containerName}</Link>
                  </TruncateText>
                </TableCell>
                <TableCell>
                  {unitConverter.convertDuration(data.average)} +/- {unitConverter.convertDuration(data.stdDeviation)} (min:{' '}
                  {unitConverter.convertDuration(data.min)}, max: {unitConverter.convertDuration(data.max)})
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default SlowTestsList;
