import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, styled } from '@mui/material';
import NavigationItem from './NavigationItem';
import {
  Alarm,
  ArrangeHorizontal,
  Book1,
  Box,
  Chart,
  Clock,
  DocumentCode2,
  Home2,
  Logout,
  ProgrammingArrow,
  SearchStatus,
  Setting2
} from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { useContext, useEffect, useState } from 'react';
import savedSearchService, { SavedSeach } from 'api/services/savedSearchService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { SearchCriteria } from 'api/services/testsService';
import { useSavedSearches } from 'contexts/SavedSearchContext';
import { OrganizationContext } from 'contexts/OrganisationsContext';

const NavigationList = styled(List)({
  mb: 1,
  mt: 1,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
});

interface NavItem {
  id: number;
  title: string;
  path: string;
  icon: JSX.Element;
}

interface Props {
  handleNavigationClick?: () => void;
}

const SearvedSearchListItem = styled(ListItemButton)({
  '.list-item-icon': {
    fontSize: '5px',
    color: '#aab2ba'
  },

  '&:hover .list-item-icon': {
    color: '#4680FF !important',
    fontSize: '6px'
  },

  '&:hover': {
    background: 'transparent',

    '.list-item-remove-icon': {
      opacity: 1
    }
  },

  '.list-item-remove-icon': {
    opacity: 0,
    transition: '0.3s',
    color: '#dc2626c9'
  }
});

const Navigation = ({ handleNavigationClick }: Props) => {
  const { selectedOrg, selectedRepo, isPublicOrg } = useContext(OrganizationContext);
  const { savedSearches, addNewSavedSearches, deleteSavedSearch } = useSavedSearches();
  const [navItems, setNavItems] = useState<NavItem[]>([]);

  useEffect(() => {
    setNavItems([
      { id: 1, title: 'Dashboard', icon: <Home2 />, path: `${selectedOrg}/${selectedRepo}/dashboard` },
      { id: 2, title: 'Tests List', icon: <SearchStatus />, path: `${selectedOrg}/${selectedRepo}/tests` },
      { id: 3, title: 'Flaky Tests', icon: <ArrangeHorizontal />, path: `${selectedOrg}/${selectedRepo}/flaky-tests` },
      { id: 4, title: 'Broken Tests', icon: <Alarm />, path: `${selectedOrg}/${selectedRepo}/broken-tests` },
      { id: 5, title: 'Slow Tests', icon: <Clock />, path: `${selectedOrg}/${selectedRepo}/slow-tests` },
      { id: 6, title: 'Coverage Stats', icon: <Chart />, path: `${selectedOrg}/${selectedRepo}/coverage` },
      { id: 7, title: 'Coverage Report', icon: <DocumentCode2 />, path: `${selectedOrg}/${selectedRepo}/coverage-report` },
      { id: 8, title: 'Pull Requests', icon: <ProgrammingArrow />, path: `${selectedOrg}/${selectedRepo}/prs` },
      { id: 9, title: 'Builds', icon: <Box />, path: `${selectedOrg}/${selectedRepo}/builds` }
    ]);
  }, [selectedOrg, selectedRepo]);

  const navigate = useNavigate();

  const fetchSavedSearches = async () => {
    if (selectedOrg && selectedRepo) {
      const data = await savedSearchService.getSaved(selectedOrg, selectedRepo);

      if (data) {
        addNewSavedSearches(data);
      }
    }
  };

  const deleteSavedSearches = async (name: string) => {
    if (selectedOrg && selectedRepo) {
      deleteSavedSearch(name);
      await savedSearchService.delete(selectedOrg, selectedRepo, name);
    }
  };

  const handleSavedSearchClick = (data: SavedSeach) => {
    try {
      const searchCriterias = Object.values(JSON.parse(data.query)) as SearchCriteria[];
      const queryParams = new URLSearchParams();
      queryParams.append('filters', JSON.stringify(searchCriterias));

      const newUrl = `${data.orgName}/${data.repoName}/tests?${queryParams.toString()}`;
      navigate(newUrl);

      if (handleNavigationClick) {
        handleNavigationClick();
      }
    } catch {
      // TODO: add proper handling
    }
  };

  useEffect(() => {
    if (!isPublicOrg) {
      fetchSavedSearches();
    }
  }, []);

  return (
    <>
      <NavigationList>
        <div id="navigation-list">
          {navItems.map((item, id) => (
            <NavigationItem key={id} item={item} handleNavigationClick={handleNavigationClick}></NavigationItem>
          ))}
        </div>
        {savedSearches.length ? (
          <>
            <ListItem sx={{ fontWeight: 600, color: '#3e4853' }}>Saved Searches</ListItem>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {savedSearches.map((data, idx) => (
                  <SearvedSearchListItem sx={{ pl: 4 }} key={idx} onClick={() => handleSavedSearchClick(data)}>
                    <ListItemIcon className="list-item-icon">
                      <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
                    </ListItemIcon>
                    <ListItemText primary={data.name} />
                    <IconButton
                      size="small"
                      sx={{ ml: 'auto' }}
                      onClick={() => deleteSavedSearches(data.name)}
                      className="list-item-remove-icon"
                    >
                      <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                    </IconButton>
                  </SearvedSearchListItem>
                ))}
              </List>
            </Collapse>
          </>
        ) : null}
      </NavigationList>
    </>
  );
};

export default Navigation;
