import { OrganizationContext } from 'contexts/OrganisationsContext';
import { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import flakyTestService, { CurrentFlakyTests } from 'api/services/flakyTestService';
import FlakyTestsList from './flaky-tests-list';
import { StyledCeleberateImg, StyledContentWrapper } from 'components/StyledCelebrationImages';
import celebrate from 'assets/images/cuckoo-boy.png';
import { useAlert } from 'contexts/AlretContext';
import CenteredContainer from 'components/CenteredLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

const BrokenTests = () => {
  const { orgName, repoName } = useParams();
  const [flakyTests, setFlakyTests] = useState<CurrentFlakyTests>();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const alertProvider = useAlert();

  const fetchFlakyTests = async () => {
    setHasError(false);

    if (orgName && repoName) {
      setIsLoading(true);

      const data = await flakyTestService.getCurrent(orgName, repoName).catch((e: Error) => {
        alertProvider.showAlert(`Problem retrieving flaky tests data. \nError: ${e.message}`);
        setHasError(true);
      });

      setIsLoading(false);

      if (data) {
        setFlakyTests(data);
      }
    }
  };

  const randomSentence = () => {
    let randomSentences: string[] = [
      "Don't stop keeping things stable!",
      'Stability is king!',
      'Only if it could always be like this!',
      'Keep up the good work!'
    ];

    const randomIndex = Math.floor(Math.random() * randomSentences.length);
    return randomSentences[randomIndex];
  };

  useEffect(() => {
    fetchFlakyTests();
  }, [orgName, repoName]);

  if (flakyTests?.quantity === 0) {
    return (
      <StyledContentWrapper>
        <Typography fontSize={42} fontWeight={700}>
          You have no flaky tests! 🎉
        </Typography>
        <StyledCeleberateImg src={celebrate}></StyledCeleberateImg>
        <Typography fontSize={36}>{randomSentence()}</Typography>
      </StyledContentWrapper>
    );
  }

  return (
    <>
      <Helmet>
        <title>Flaky Tests</title>
      </Helmet>
      <Typography variant="h2" mb={2}>
        Flaky Tests
      </Typography>
      {flakyTests ? <FlakyTestsList flakyTests={flakyTests} /> : null}

      {isLoading ? (
        <CenteredContainer>
          <CircularProgress></CircularProgress>
        </CenteredContainer>
      ) : null}

      {hasError ? (
        <CenteredContainer>
          <Typography ml={1} fontWeight={500} fontSize={'1.5rem'}>
            Data is not available
          </Typography>
        </CenteredContainer>
      ) : null}
    </>
  );
};

export default BrokenTests;
